import { Link, Typography } from "@mui/material";

function AboutRoadmap(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>What's in the plan for tokemax?</Typography>

            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                We never stop delivering features to help our customers. Here are some of the key features we are working to deliver soon. 
            </Typography>
            <ul>

                <li>Beta customer trial</li>
                <li>General Availability </li>
                <li>Add support for additional blockchains such as polygon </li>
                <li>Support browser wallets such as MetaMask</li>
                <li>Native support for purchasing eth (rather than using a wallet that already contains eth) </li>
            </ul>

            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Let us know about a feature you would like to see by sending us a <Link to='/about/contact'>message</Link>. 
            </Typography>

        </>
    )
}  

export default AboutRoadmap;
