import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function AboutDigCol(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={6}>Digital Collectibles</Typography>

            <Typography variant='h4'>What are they?</Typography>
            <Typography lineHeight={2} marginTop={2} marginBottom={4}>  
                Digital collectibles are unique digital tickets that represents a record of ownership. <br/>
                tokemax creates digital collectibles as <HashLink to='/about/blockchain#what-are-nfts'>NFTs</HashLink> (or Non Fungible Tokens) and stores them on the blockchain.<br/>
                Digital collectibles are stored using common standards meaning they can be accessed by many other existing websites including marketplaces, online communities and games. 
            </Typography>

            <Typography variant='h4'>How can I get one?</Typography>
            <Typography lineHeight={2} marginTop={2} marginBottom={4}>
                After you have made a purchase at a participating vendor, the order details will be available on tokemax.<br/>
                At the time of purchase, you will be provided a unique one time link that enables you to claim the purchase on tokemax. <br/>
                Once you have registered as the owner the purchase, you will be able to add additional information (such as serial number or description of the experience). <br/>
                You can then register the collectible on the blockchain by creating an NFT that contains all the key information about the purchase. <br/>
                The NFT that is created can be displayed in your wallet or traded on existing trading platforms. <br/>
            </Typography>

            <Typography variant='h4'>Where are they stored?</Typography>
            <Typography lineHeight={2} marginTop={2} marginBottom={4}>
                Digital collectibles are stored primarily on the blockchain with some detail stored separately and linked to the details on the blockchain.<br/>
                
                Digital collectible data on the blockchain is associated with an account that you own through the use of special identifiers called keys. <br/> 
                
                You can create accounts to contain your digital collectibles in the <Link to='/profile'>profile</Link> section. You can view your accounts and existing digital collectibles in the same profile section.<br/>
            </Typography>

        </>
    )
}  

export default AboutDigCol;
