import { Network, Alchemy } from "alchemy-sdk";

const ethers = require('ethers');

const bc_config = {
//    apiKey: "OE-mTsIoEzl9c9oJUBXNhJHtVGro26Pv", 
//    network: Network.ETH_GOERLI,  
    apiKey: process.env.REACT_APP_ALCHEMY_KEY, 
    network: process.env.REACT_APP_ALCHEMY_NETWORK,  
  }

const alchemy = new Alchemy(bc_config);
 
export async function getNfts(ownerAddr) {
    console.log("fetching NFTs for address:", ownerAddr);

    return alchemy.nft.getNftsForOwner(ownerAddr);

}

export async function getOwners(contractAddr, tokenId) {

    console.log("fetching owners for contract " + contractAddr + " and token id: " + tokenId);

    return alchemy.nft.getOwnersForNft(contractAddr, tokenId);

}

export async function getTransfers(contractAddr, tokenId) {

    const address = [contractAddr];


    const response = alchemy.core.getAssetTransfers({
        fromBlock: "0x0",
        contractAddresses: address,
        category: ["erc721"],
        excludeZeroValue: false,
        withMetadata: true
      });

    return response;

}

export async function getContractMetadata(address) {
    console.log("fetching NFT contract metadata for address:", address);

    return alchemy.nft.getContractMetadata(address);

}

export async function getNftsForContract(address) {
    console.log("fetching NFTs for contract address:", address);

    return alchemy.nft.getNftsForContract(address, {
        omitMetadata: false,
        tokenUriTimeoutInMs: 5000
      });

}

export async function getOwnersForContract(address) {
    console.log("fetching owners of NFTs for contract address:", address);

    return alchemy.nft.getOwnersForContract(address);

}

export async function getBalanceInEth(address) {

    const bal = await alchemy.core.getBalance(address, 'latest');

    const ethValue = ethers.utils.formatEther(bal);
    console.log('balance in eth: ' + ethValue);

    return ethValue

}
