// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import {getAuth} from 'firebase/auth';

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

/*
const firebaseConfig = {
  apiKey: "AIzaSyAPA4GCl7C1jhxwq9ixnEkMFnSPTG37kVE",
  authDomain: "tokemax.firebaseapp.com",
  projectId: "tokemax",
  storageBucket: "tokemax.appspot.com",
  messagingSenderId: "805252249501",
  appId: "1:805252249501:web:9c230555413ef360bea174",
  measurementId: "G-WXED4JPP3B"
};
*/

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const recaptchaKey = process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY;

export function getFirebaseConfig() {

  //console.log('firebase config: ' + JSON.stringify(firebaseConfig));

  if (!firebaseConfig || !firebaseConfig.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js');
  } else {
    return firebaseConfig;
  }
}

export function isUserSignedIn() {
  //var result = !!auth.currentUser;

  //console.log('isUserSignedIn, current user: ' + auth.currentUser);

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  //console.log('isUserSignedIn, isAuthenticated: ' + isAuthenticated);

  return isAuthenticated === 'true';
}

export function setUserSignedIn(isSignedInFlag) {
  localStorage.setItem("isAuthenticated", isSignedInFlag);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/*
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptchaKey),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
*/

//const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
