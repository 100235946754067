import React from 'react';

import { isUserSignedIn, auth } from '../firebase_config.js';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Stack } from '@mui/material';
import AboutOverview from '../about/overview.js';

import BannerPng from '../assets/blog-picture-customer-engagement.jpg'

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name: '', date: new Date()};
  }

  componentDidMount() {

    if (isUserSignedIn() && auth.currentUser)
      this.setState({name : auth.currentUser.displayName});
  }

  componentWillUnmount() {

  }
  
  render() {

    var regLogin;
    if (!isUserSignedIn()) {
      regLogin = <>Please <Link to='/login'>login</Link> or <Link to='/register'>register</Link> discover more.<br/></>
    }

     return (
      <>
                
        <Box paddingTop={5} height={0.3} maxHeight={0.3} width={1} border={0}>
            <Typography variant='h2' color='#4568dc'>Welcome to <strong>tokemax</strong></Typography>
            
            <Typography lineHeight={2} marginTop={3} maxWidth={{ xs: 1, md: 0.75 }} align='justify'>  
              tokemax provides services to brands and consumers to create digital collectibles from everyday purchases. <br/>
              <br/>
              Create and deploy smart contracts to the blockchain for your products then your customers can create digital collectibles in the form of NFTs that can promote community engagement with your customers and target market. <br/>
              <br/>
              Read on to learn more about the services that we can offer to your business. <br/>

              {regLogin}
            </Typography>
        </Box>

        <Box paddingTop={5} width={1} border={0} alignItems='center'>
          <AboutOverview/>
        </Box>

        <Box paddingTop={5} >
          <Typography variant='h3' color='#4568dc'>Find out more</Typography>
          <Typography lineHeight={2} marginTop={2} marginBottom={4} align='justify'>
              Learn about adding products and orders to allow your customers to create digital collectible as NFTs. <br/>
              Learn about claiming your purchased NFT-enabled products and creating digital collectibles. <br/>
              Learn about how to manage your wallets, add credit to purchase NFTs and view and sell your tokens.<br/>
              <Link to='/about/start'>Learn More</Link>
          </Typography>
        </Box>
      </>      
      );
  }
}
/*

                  <Box component='img' src={BannerPng} width='0.3' paddingRight={30}/>
                  <img style={{ width: "30%", height: "30%" }} src={BannerPng} alt="banner" />
                  */

export default Welcome;