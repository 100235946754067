import { Link, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";

function AboutPurchases(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Managing your Purchases</Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>Overview</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Registering a purchase enables a buyer to create a digital collectible from a real world purchase of an eligible product.<br/>
                Purchases are made directly between a seller (such as a retailer) and a buyer (a consumer). tokemax is not involved in this step.<br/>
                Once the purchase is complete, the seller will send the order details to tokemax and also provide an order reference to the buyer.<br/>
                The buyer can claim the <Link href='/orders'>order</Link> as theirs, when they register their purchase and optionally create digital collectibles for that order.<br/>
                To see your purchases, go <Link href='/purchases'>here</Link>. 
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>The Purchase Process</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
                The transaction between the buyer and the seller, including purchase, payment and shipping does not involve tokemax. This part happens as it normally does.<br/>

                The next step before a purchase can be registered is for the buyer to <HashLink to='/about/products#create-product-line'>create a product line</HashLink> and associated product. <br/>
                
                Once the product is created, the seller then needs to <HashLink to='/about/products#create-order'>create an order</HashLink> 
                for the transaction (in the same way that they do in their existing order management systems). An order reference is created for the order, which needs to be provided to the buyer. <br/> 

                If the buyer has not already done so, they will need to <HashLink to='/about/register#create-account'>register</HashLink> for an account with tokemax.<br/>

                Once the buyer has the order reference, they can use that reference to register themselves as the owner or recipient of the order. 
                See the <HashLink to='#register-purchase'>register a purchase</HashLink> section below for more details on this step.<br/>

                When the buyer registers a purchase for a given order, a <HashLink to='#create-digital-collectible'>digital collectible</HashLink> is created that represents that purchase. <br/>

                The digital collectible for the purchase is stored in the buyers online wallet and can be viewed in the <Link to='/profile'>profile</Link> page. <br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='register-purchase'>Register a Purchase</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                In order to register as the buyer who made the purchase for a particular order, you will need an 
                order reference as well as the Product SKU for the line item in that order. <br/>

                The order reference needs to be provided by the sellor, either at the time of purchase or later via email or similar processes.<br/> 
                The product SKU is likely printed on your physical receipt but may also be provided separately by the seller. <br/>

                An alternative approach to the above includes scanning a QR code at the time of order creation. 
                On the view orders page, looking at the detail of an order and clicking the Product SKU will take you to a page with a purchase registration 
                form and a QR code that will allow you to register the purchase as well. Anyone with the QR code link (or indeed the order reference and product details) can register the order as their own. 
                If the digital collectible is used for purchase authentication purposes, then the order reference should be considered sensitive information.<br/>

                When registering a purchase, the buyer has the option of <HashLink to='#create-digital-collectible'>creating a digital collectible</HashLink> that represents the purchase. 
                Currently, the digital collectible must be created at the time that the purchase is registered. 
                There is a feature on the roadmap (#20) to enable creating digital collectibles after registration. <br/>

                There can only be a single registered owner of a purchase and only a single online wallet can contain the associated digital collectibles at any one time. <br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='create-digital-collectible'>Creating a Digital Collectible</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  

                When a buyer registers a purchase, they can choose to create a digital collectible for that purchase. 
                The digital collectible is created using details from the product that was purchased (including name, image, links etc) 
                as well as order details (date, amount etc) and details provided by the buyer at the time of purchase.<br/>

                The digital collectible is created (also known as minted) as an <HashLink to='/about/blockchain#what-are-nfts'>NFT</HashLink> 
                on the <HashLink to='/about/blockchain#what-is-ethereum'>ethereum blockchain</HashLink> in a process known as minting. 
                There is a feature on the roadmap (#21) to enable support for other blockchains. <br/>

                When a digital collectible is created on the blockchain, a small fee needs to be paid to the blockchain community (known as the network) to do the work. 
                This is described in more detail in the <HashLink to='#paying-for-a-digital-collectible'>section below</HashLink>.

                The information included in the digital collectible is publicly available and should therefore not include any identifiable information such as individual's names or contact details. 
                This is especially true for the additional information that can be added by a buyer, when a purchase is registered as their own.<br/>

                A digital collectible is created using the smart contract associated with the product that was ordered. 
                This provides tracability on the blockchain of the digital collectible from the seller to the buyer which is key to 
                enabling <Link to='/about/authenticity'>purchase verification</Link> capabilitites. <br/>

                When the digital collectible is a created, it is associated with the default <HashLink to='/about/blockchain#online-wallet'>online wallet</HashLink> linked to the account of the buyer. 
                The digital collectible can be viewed in the <Link to='/profile'>profile</Link> page. <br/>

                Once a digital collectible is created and stored in your online wallet, it is accessible to any and all communities and online services that interact with NFTs. 
                See the <HashLink to='#paying-for-a-digital-collectible'>section below</HashLink> for more details.<br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='paying-for-a-digital-collectible'>Paying for a Digital Collectible</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                A fee needs to be paid to the blockchain network for creating the digital collectible as an NFT. Currently, the fee is paid by the owner of the product in the order.
                There is a feature (#22) on the roadmap to enable a buyer to pay the fee for creating the digital collectible. <br/>

                The fee charged for creating a digital collectible can vary based on a number of factors including the current price of ether as well as how busy the blockchain network is. 
                The fee is paid to the network using <HashLink to='/about/blockchain#what-is-ether'>ether</HashLink>. <br/>

                Typically, the digital collectible costs around 50 cents AUD to create. 
                An example test token can be seen here <Link href='https://goerli.etherscan.io/tx/0xd46a130f98a8a1548612c329a68e048b9d3f2498906050f4895c011ebb38d0fe'>here</Link>. 
                Looking at the transaction fee field, we can see it cost 0.0002 ether, which in todays currency is about $AUD0.50.<br/>
                There is a feature (#25) on the roadmap to enable the estimation of a fee prior to creating the digital collectible. <br/>

                The ether used to pay the fee needs to be credited to the <HashLink to='/about/profile#wallet-types'>default</HashLink> online wallet of the buyer. 
                The ether needs to be transferred to the buyer's online wallet using a <HashLink to='/about/blockchain#what-is-a-crypto-exchange'>crypto exchange</HashLink>.<br/> 
                There is a feature (#23) on the roadmap to enable transfer of crypto currency without the need for an exchange. <br/>
            
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='view-your-purchases'>View Your Purchases</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                To see your purchases, go to the <Link href='/purchases'>purchases</Link> page. <br/>

                In the view purchases page, you can see the details of the purchase including
            </Typography>
            <ul>
                <li>Purchase date</li>
                <li>Order ID</li>
                <li>Product SKU</li>
                <li>Serial number - added by the buyer at purchase registration time.</li>
            </ul>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                If a digital collectible has been created for the purchase, the following details about the digital collectible are also available:
            </Typography>
            <ul>
                <li>The date the digital collectible was created</li>
                <li>Token ID - a unique ID for the digital collectible within the collection created for a particular product (and its smart contract)</li>
                <li>Token hash - the unique ID for the digital collectible on the blockchain. 
                    The token hash value provides a link to view the digital collectible details on the community blockchain browser tool - etherscan</li>
                <li>Metadata - a file that contains <Link href='https://www.binance.com/en/blog/nft/lets-get-technical-what-is-nft-metadata-80655932618109691'>additional</Link> 
                    details about the digital collectible. This file is read by community tools in order to understand more about the digital collectible. </li>
            </ul>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                To see the digital collectibles created for a purchase, go to the <Link to='/profile'>profile</Link> page and click on the address of the wallet that contains the digital collectibles. 
                More information can be found <HashLink to='/about/profile#view-your-online-wallet'>here</HashLink>.
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='use-your-digital-collectible'>How To Use a Digital Collectible</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                
            </Typography>

        </>
    )
}  

export default AboutPurchases;
