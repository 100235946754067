import { Link, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";

function AboutRegister(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Registering an Account with tokemax</Typography>
            
            <Typography variant='h4' marginTop={4} marginBottom={4} id='create-account'>Create an Account</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  

                You can register an account with tokemax <Link href='/register'>here</Link> <br/>

                Creating an account enables you <HashLink to='/about/products#create-product-line'>create products</HashLink> 
                or <HashLink to='/about/purchases#register-purchase'>register purchases</HashLink> in order to create digital collectibles.<br/>

                When you create an account, a default <HashLink to='/about/blockchain#online-wallet'>online wallet</HashLink> will be created for you. 
                You can manage your wallets by going to the <Link href='/profile'>profile</Link> page. <br/>

                Learn more about managing wallets and your <Link href='/about/profile'>profile</Link> here.
            </Typography>


        </>
    )
}  

export default AboutRegister;
