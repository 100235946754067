import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AboutLoyalty(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Loyalty Programs</Typography>

            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
                Be rewarded for your loyalty to your favourite brands by a creating digital collectible for each purchase. <br/>

                The more digital collectibles you create, the more benefits you can receive from your favourite brands. <br/>

                The benefits you receive for loyalty to a particular brand are determined by the company that own the brand. 
                Examples may include exlusive experiences, discounts or access to online content. <br/>

                The collectibles you earn are the same regardless of where a purchase was made, meaning you get the same benefits if 
                you purchased from the store down the road, the large retailer at the mall or even online. 
                This allows you to benefit from your loyalty to a brand regardless of how you shop. <br/>

                Because your loyalty to a brand is stored as a digital collectible, you have access to all the benefits associated 
                with digital collectible outside of specific loyalty programs. <br/>
                
                <Link to='/about/collectibles'>Learn More</Link> about digitial collectibles.

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='loyalty-show-off'>Show off Your Loyalty</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>

                Because your loyalty to a brand is captured as digital collectibles (NFTs on the blockchain) they are available for all to view. <br/>

                Show off your loyalty to your favourite brands on your online profile or any community platform that is NFT enabled. <br/>

                Because different brands can see the collectibles you have from other brands, they can offer you additional 
                benefits based on partnerships they have with those brands. <br/>

                Your loyalty to a particular brand may make you a more desirable customer to a competing brand. 
                Being able to demonstrate your existing loyalty to new brands could provide access to special offers with a new brand that you've not tried before. <br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='brand-community-loyalty'>Joint Loyalty Programs Across Brands</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
                Brands can join a community of other like minded brands to create programs that reward buyers who are loyal to any of the community members. <br/>

                Multiple brands could partner together to combine their loyalty programs to provide even greate benefits. <br/>

                Or a single company that has multiple different brands can have a single loyalty program that provides benefits to customers of one of their brand, 
                encouraging redemption at another brand. This can further encourage customers to try products and experiences from a company's other brands. 
            </Typography>
        </>
    )
}  

export default AboutLoyalty;
