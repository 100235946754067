
import React from 'react';

import Products from './Products';

import {  getFirestore, doc, collection, query, where, getDocs, getDoc, limit } from "firebase/firestore";
//import { initializeApp } from 'firebase/app';
import { auth, isUserSignedIn } from '../firebase_config.js';
import { Box, Collapse, IconButton, Typography, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableHead, Tooltip, Alert} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Link, Navigate } from "react-router-dom";

//initializeApp(getFirebaseConfig());

//import { isUserSignedIn } from './auth.js';

class ProductLine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {productLineId: null, productLine : null};

    this.getProduct = this.getProductLine.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.createAuthHook();
      
  }

  createAuthHook() {
    
    auth.onAuthStateChanged(async (user) => {
      if (user) {

        this.setState(this.getDefaultState(user));

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      owner: null,
      open: false
    }

  }

  async componentDidMount() {
    this.getProductLine();

  }

  componentWillUnmount() {

  }

  async getProductLine() {

    if (this.props.data != null) {
    
      // console.log('loading product line data into state from ' + this.props.data.data());

      this.setState({
        productLineId : this.props.data.id,
        productLine : this.props.data.data(),
        // TODO: remove this once theres conditional checks
        name: this.props.data.data().productLineName ,
        desc: this.props.data.data().productLineDescription ,
      });

      this.handleClick(this.props.data.id);

    } else if (this.props.id != null) {
      console.log('getting product line with id ' + this.props.id);

      const docRef = doc(getFirestore(), "productLines", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        productLineId : docRef.id,
        productLine : result.data(),
        // TODO: remove this once null checks are implemented
        //name: result.data().productLineName ,
        //desc: result.data().productLineDescription ,
      });
    } else if (this.props.contractAddress != null) {
      console.log('retrieving product line with contract address ' + this.props.contractAddress)

      const  psRef = collection(getFirestore(), "productLines") ;

      const q = query(psRef, where("contract.address", "==", this.props.contractAddress), limit(1));

      const querySnapshot = await getDocs(q);
      let plData = querySnapshot.docs.map(doc => doc);

      if (plData.length > 0) {
        console.log('retrieved: ' + JSON.stringify(plData[0]?.data()))

        this.setState({ 
          productLineId: plData[0]?.id,
          productLine: plData[0]?.data() ,
        });
      } else {
        this.setState({ 
          severity: 'error',
          message: 'No product lines found with contract address ' + this.props.contractAddress,
        });
      }


    } else {
      console.log('no query params or props: ' + JSON.stringify(this.props));
      this.setState({ 
        severity: 'error',
        message: 'No product line details provided',
        });
    }
  }

  /**
   * Used to expand / collapse product rows
   */
  handleClick(key) {

    let value = this.state.open

    if (value == null)
      value = false;
    else
      value = !value;

    //console.log(`in handle click, key: ${key}, value: ${value}`);

    this.setState(
      {
        [key]: value,
        open: value
      }
    );
  }
  

  render() {

    if (!isUserSignedIn()) {
      //console.log("productline.render, user not signed in, redirecting to login");
      return <Navigate to="/login" replace={true} />
    }

    //console.log('ProductView render');

    if (this.state.message) {
      return(
        <>
          <Typography variant='h2'>
            Product Line
          </Typography>
          <br/>
          <Alert severity={this.state.severity}>
            {this.state.message}
          </Alert>
        </>);
    }

    let productLine;
    if (this.state.productLine !=null) {

      let open = this.state.open;

      productLine = 
      <>
      <TableRow
        key={this.state.productLineId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => this.handleClick(this.state.productLineId)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">{this.state.productLineId}</TableCell>
        <TableCell align="left">{this.state.productLine.productLineSymbol}</TableCell>
        <TableCell align="left">{this.state.productLine.productLineName}</TableCell>
        <TableCell align="left">{this.state.productLine.productLineDescription}</TableCell>
        <TableCell align="left">
          <Link to={'/productline/contract/'+ this.state.productLine.contract.address}>
            {this.state.productLine.contract.address}
          </Link>
        </TableCell>

      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Product Details
              </Typography>
              {renderProducts(this.state.productLineId)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>
    } else {
      //console.log(JSON.stringify(this.state));
    }

    return (
    <>
      {productLine}   
    </>
        
    );
  }

}

function renderProducts(productLineId) {
  if (productLineId != null) {
    return (
      <>
      <Products productLineId={productLineId}/>
      </>
    );
  } else {
    return '';
  }
}

export default ProductLine;

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}


