import React from 'react';

import { Alert, Typography, TextField, Button, Box, MenuItem } from "@mui/material";
import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";

import { auth } from '../firebase_config.js';

class AboutContact extends React.Component {

    constructor(props) {
      super(props);
      this.state = this.getDefaultState();
  
      this.saveData = this.saveData.bind(this);
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

    }

    getDefaultState() {
      return {
        email: '', status: 'NEW', type: '', detail : ''
      }
    }
  
    async componentDidMount() {
  
    }
  
    componentWillUnmount() {
  
    }

    handleChange(event) {
      //this.setState({value: event.target.value});
  
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
  
      //console.log(`setting state to ${name} = ${value}`)
  
      this.setState({
        [name]: value
      });
  
    }
  
    async handleSubmit(event) {
      //console.log('Register form submitted: ' + this.state.email);
      event.preventDefault();
  
      this.saveData();
  
    }

    async saveData() {

      
      if (!auth.currentUser && !this.state.email) {
          this.errorState('If you are not logged in, you need to provide an email address');
            return;
      }
  
      try {
    
        let userId, email;
        if (auth.currentUser) {
            userId = auth.currentUser.uid;

            if (auth.currentUser.email)
                email = auth.currentUser.email;
        } else
        
        if (!email)
            email = this.state.email;

        var type = this.state.type;
        var status = this.state.status;
        var detail = this.state.detail;
            
        var data = {
            email: email,
            type: type,
            status: status,
            detail: detail,
            createTime: serverTimestamp()
        }
        
        if (userId)
            data.owner = userId;

        console.log(`about to save a message: ${JSON.stringify(data)}`);
  
        var docRef = await addDoc(collection(getFirestore(), 'messages'), data);
    
        console.log(`message saved: ${docRef.id}`)
        this.messageState(`Your message has been submitted (reference ${docRef.id})`)
  
        this.setState(this.getDefaultState());
      } catch (error) {
        console.error('Error submitting your message', error);
        this.errorState(`Error submitting your message: ${error}`)
      }   
    }
  
    errorState(message) {
      this.setState(
        {
          message: message, 
          severity: 'error'
        }
      );
    }
  
    messageState(message) {
      this.setState(
        {
          message: message, 
          severity: 'success'
        }
      );
    }
    
    render() {

        let emailInput = <></>;
        if (!auth.currentUser) {
            emailInput = 
            
            <TextField
                        id="email"
                        label="Email address"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        autoComplete="true" required
                        margin="normal" 
                        fullWidth
    
                    />
        }
    
        let message;
        if (this.state.message) {
            message = 
            <><Alert severity={this.state.severity}>{this.state.message}</Alert><br/></>
        }

        return (
            <>
                <Box sx={{ display: 'grid', maxWidth: '0.5' }} marginBottom={4}>

                    <Typography variant='h2' marginTop={4} marginBottom={4}>Getting in contact</Typography>

                    <Typography marginBottom={2} lineHeight={2}>  
                        Use this form to get in contact with us. 
                        <br/>
                        We will do our best to reply in a couple of days. <br/>

                    </Typography>

                    {message}

                    <form id="contact-form" onSubmit={this.handleSubmit}>

                        {emailInput}

                        <TextField
                            id="contact-type"
                            label="Reason"
                            name="type"
                            value={this.state.type}
                            onChange={this.handleChange}
                            autoComplete="true" required
                            margin="normal" 
                            fullWidth
                            select
                        >
                            <MenuItem key='general-enquiry' value='general-enquiry'>
                                General Enquiry
                            </MenuItem>
                            <MenuItem key='feature-request' value='feature-request'>
                                Feature Request
                            </MenuItem>
                            <MenuItem key='bug-report' value='bug-report'>
                                Bug report
                            </MenuItem>
                            <MenuItem key='other' value='other'>
                                Other
                            </MenuItem>
                        </TextField>
                        <br/>
                        <TextField
                            id="detail"
                            label="Message"
                            name="detail"
                            value={this.state.detail}
                            onChange={this.handleChange}
                            autoComplete="true" required
                            multiline rows={6}  
                            margin="normal"
                            fullWidth
                            inputProps={{maxWidth: 200}}
                        />
                        <br/>
                        <Button id="contact-submit" type="submit" variant="contained">Submit</Button>

                    </form>
                </Box>

            </>
        )
    }  

}

export default AboutContact;
