import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";


import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import {
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    signOut,
    signInWithEmailAndPassword,
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider,
    GithubAuthProvider,
    PhoneAuthProvider,
    //CredentialHelper,
    AuthUI
  } from 'firebase/auth';

// import { initializeApp } from 'firebase/app';
import { getFirebaseConfig, auth, app as firebaseApp, setUserSignedIn } from '../firebase_config.js';
import 'firebase/compat/auth';
import { Box, Typography } from '@mui/material';

  
  function Login() {

    const [isSignedIn, setIsSignedIn] = useState(false); 
    const location = useLocation();
    const navigate = useNavigate();

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
          const isSignedInFlag = !!user;

          //console.log('login.authStateChange, user: ' + user + ", isSignedInFlag: " + isSignedInFlag);
          setIsSignedIn(isSignedInFlag);

          //localStorage.setItem("isAuthenticated", isSignedInFlag);
          setUserSignedIn(isSignedInFlag)
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (isSignedIn) {
      return (
        <Navigate to="/" replace state={{ path: location.pathname }}/>
        );

    } 
    
    return (
            
      <Box 
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        >
        <Typography variant="h2">Log in</Typography>

        <Typography marginTop={2} marginBottom={2} lineHeight={2}>
          Please log in using one of the options below. <br/>
          Dont have an account? register <Link to="/register">here</Link>.
        </Typography>

        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </Box>    
    );

  }

export default Login;


const uiConfig = {

    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
      uiShown: function() {
        // The widget is rendered.
        // Hide the loader.
        //document.getElementById('loader').style.display = 'none';
      },
      signInFailure: function (error) {
          console.log('singInFailure: ' + error.code);
        // For merge conflicts, the error.code will be
        // 'firebaseui/anonymous-upgrade-merge-conflict'.
        if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
          return Promise.resolve();
        }
        // The credential the user tried to sign in with.
        var cred = error.credential;
        // Copy data from anonymous user to permanent user and delete anonymous
        // user.
        // ...
        // Finish sign-in after data is copied.
        return auth.signInWithCredential(cred);
      }
  
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
  
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD, // alt is EMAIL_LINK_SIGN_IN_METHOD  
        requireDisplayName: true, // this is the default, keeping here as an example
        forceSameDevice: false,
      },
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        scopes: [
          'email'
        ],
  
      },
      {
        provider: FacebookAuthProvider.PROVIDER_ID,
        scopes: [
          'public_profile',
          'email',
        ],
  
      },
      TwitterAuthProvider.PROVIDER_ID,
      GithubAuthProvider.PROVIDER_ID,
      {
        provider: PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'AU',
        defaultNationalNumber: '0412 345 678',
  
      }
    ],
    autoUpgradeAnonymousUsers: true,
    // Required to enable one-tap sign-up credential helper.
    //credentialHelper: CredentialHelper.GOOGLE_YOLO
    // Other config options...
  //  signInSuccessUrl: '<url-to-redirect-to-on-success>',
    // Terms of service url.
  //  tosUrl: '<your-tos-url>',
    // Privacy policy url.
  //  privacyPolicyUrl: '<your-privacy-policy-url>'
  }
  