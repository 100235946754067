import { Button, Link, List, ListItem, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";

function AboutProducts(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Managing Your Products</Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} >Overview</Typography>
            <Typography marginTop={4} marginBottom={4} lineHeight={2}>

                You can create product lines and add products to that product line. <br/>
                Product lines are groups of similar variations of products. <br/>
                Typically a product has a unique SKU or stock keeping unit as well as other attributes that describe the product. <br/>
                When a product line is created, certain details are needed to describe the product line. <br/>
                
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}  id='create-product-line'>Create a Product Line and Products</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  

                You can add a new product line <Link underline="hover" to='/product/edit'>here</Link>.<br/> 
                You can provide details about a product line including name, description and symbol.<br/>
                A product line symbol is a key attribute of the smart contract (described further below) so should be something meaningful but short and in all caps (which follows the standard adopted for NFTs, which this value is used for).<br/>
                When you create a product line, a smart contract is automatically generated for you and deployed to the blockchain. 
                See the below section 'Creating a smart contract for your product line' for more details on this process. 
                Creating a smart contract requires payment to the network. Payment costs can vary. During testing, network fees have been around $AUD 5. 
                Be sure to create a contract immediately - this is the only option available currently (there's a feature on our roadmap to make this optional initially).
                When you create a new product line, the product line ID will be displayed. This can be used on the Add Product form in the Product Line Id field. 
                This page also allows you to add a product using unique Product Line Id from the create product line form. <br/>
                <br/>
                When creating a product, you can provide details about the product including:
            </Typography>
            <ul>
                <li>SKU - unique code for for your product. must be unique across all product lines. </li>
                <li>Name - display name. Used in the NFT. </li>
                <li>External link - link to a website that contains additional details about the product. Used in the NFT. </li>
                <li>Product image URL - used as the primary image for the product. Used in the NFT to display in marketplaces. </li>
                <li>Attributes - keys and values that further describe the product. Used in the NFT and determines how the NFT is displayed in marketplaces. 
                See <a href='https://docs.opensea.io/docs/metadata-standards'>here</a> for more details on how these attributes are used in the Opensea marketplace. </li>
            </ul>

            <Typography variant='h4' marginTop={4} marginBottom={4} >Viewing product lines and products</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                You can view the product lines that you have created <Link underline="hover" href='/products'>here</Link>.<br/>
                Access details provided when the product line was created such as name, description and symbol. <br/>
                Additionally, if a smart contract was created the contract address will be shown with a link to view more details about the contract.<br/> 
                You can add products to a product line by expanding an already created product using the drop down. See the creating product lines and products section for more details on this topic.<br/>
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} >Creating a smart contract for your product line</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Product line detail is used to define a <HashLink to='/about/blockchain#smart-contract'>smart contract</HashLink> 
                that represents that product line on the <HashLink to='/about/blockchain#what-is-the-blockchain'>blockchain</HashLink>. 
                
                When a product line is created, a smart contract is automatically deployed to the blockchain. 
                As the owner of the product line, you are also the owner of the smart contract on the blockchain. 
                Your ownership is established via the default online wallet you have configured in your <Link href='/profile'>profile</Link>. 
                Learn more about setting up online wallets in the <Link href='/about/profile'>About Profile</Link> page. <br/>

                When a product is purchased and used to create a digital collectible, the smart contract associated with the product line will 
                produce the digital collectible and be the original owner of the digital collectible on the blockchain even after being transferred to the consumer.<br/> 
                
                Creating a contract is required when you create a product line. There is a feature on our roadmap (#15) to make creating contracts optional (wait until its needed). 
                Your customers wont be able to create digital collectibles from your products until there is a smart contract deployed. <br/>

                Deploying a contract to the blockchain requires payment to the blockchain network in the form of <HashLink to='/about/blockchain#what-is-ethereum'>ether</HashLink>. 
                There is a feature on our roadmap (#19) to transfer ether to your wallet but until that time, you will need to use a wallet that already has ether.
                 
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='create-order'>Creat an Order</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Once you have created products and your customer has purchased an eligible product, you can create an order to capture the details.<br/>
                
                An order represents the sellers view of the sale transaction and contains certain details about the purchase such as the product details, 
                the number of line items (products), merchant details and total amounts. <br/>

                An order for a product can only be created by the same account that created the product line and product. This means you cannot create an order for someone else's products.<br/>

                An order requires the product SKU of the product that was purchased. 
                Currently, ordering only supports a single line item. There is a feature in our roadmap (#18) to enable multiple line items for an order. <br/>

                When the order is created, an order reference is generated. This order reference needs to be sent to your customer so they can <HashLink to='/about/purchases#register-purchase'>register a purchase</HashLink> receipt for the order.<br/>

                There is a feature in our roadmap (#17) to enable an order to be created with an email that will allow a customer to begin the purchase registration process via an email.<br/>

                There is a feature in our roadmap (#16) to enable API integration between customer order management systems and tokemax. <br/>

            </Typography>

        </>
    )
}

export default AboutProducts;
