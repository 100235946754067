import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, FormControl, InputLabel, Select, MenuItem} from '@mui/material';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";
//import {getStorage,ref,uploadBytesResumable,getDownloadURL,} from 'firebase/storage';

// import {getAuth} from 'firebase/auth';
//import { initializeApp } from 'firebase/app';
import { isUserSignedIn, auth } from '../firebase_config.js';
//initializeApp(getFirebaseConfig());

const ethers = require('ethers');

class WalletEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getWallet = this.getWallet.bind(this);
    this.saveWallet = this.saveWallet.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  getDefaultState() {
    return {date: '', label: '', publicKey: '', privateKey: '', address : '', mnemonic : '', purpose: ''}
  }

  async componentDidMount() {
    this.getWallet();

  }

  componentWillUnmount() {

  }

  handleChange(event) {
    //this.setState({value: event.target.value});

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    // console.log(`setting state to ${name} = ${value}`)

    this.setState({
      [name]: value
    });

  }

  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    this.saveWallet();

  }

  async getWallet() {

    if (this.props.id != null) {
    
      console.log('getting wallet with id ' + this.props.id);

      const docRef = doc(getFirestore(), `userProfile/${auth.currentUser.uid}/wallets`, this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        walletId: docRef.id,
        wallet: result.data()
      });
    } else if (this.props.data != null) {
      this.setState({ 
        walletId: this.props.walletId,
        wallet: this.props.data
      });
    }

  }


  async saveWallet() {

    if (!isUserSignedIn())
      return;

    try {
  
      var owner = auth.currentUser.uid;
      var label = this.state.label;
      var publicKey = this.state.publicKey;
      var privateKey = this.state.privateKey;
      var address = this.state.address;
      var mnemonic = this.state.mnemonic;

      let wallet;

      if (!mnemonic && 
        !publicKey &&
        !privateKey &&
        !address) {
          wallet = ethers.Wallet.createRandom();
          mnemonic = wallet.mnemonic.phrase;
          console.log('creating random wallet, address: ' + wallet.address);
      } else if (mnemonic && 
        !publicKey &&
        !privateKey &&
        !address) {
          wallet = ethers.Wallet.fromMnemonic(mnemonic)
          console.log('creating a wallet from a mnemonic, address: ' + wallet.address);
      }

      publicKey = wallet.publicKey;
      privateKey = wallet.privateKey;
      address = wallet.address;

      console.log(`about to save a wallet with ${owner}, ${publicKey}, ${address}`);
  
      var data = {
        owner: owner,
        label: label,
        publicKey: publicKey,
        privateKey: privateKey,
        address: address,
        mnemonic: mnemonic,
        purpose: this.state.purpose,
        timestamp: serverTimestamp()
      }

      var docRef = await addDoc(collection(getFirestore(), `userProfile/${owner}/wallets`), data);
  
      console.log(`wallet saved: ${docRef.id}`);

      this.setState(this.getDefaultState());
    }
    catch(error) {
      console.error('Error saving new wallet', error);
    }

      
  }


  render() {

    return (
      <div className="WalletEdit">

        <Typography variant="h4">Edit Wallet</Typography>
        <br/><br/>

        <form id="wallet-form" onSubmit={this.handleSubmit}>

          If you only supply a label, a random wallet will be generated.
          <br/><br/>
          <TextField
            id="wallet-label"
            label="Wallet Label"
            name="label"
            value={this.state.label}
            onChange={this.handleChange}
            autoComplete="true" required
          />

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="wallet-purpose-label">Purpose</InputLabel>
            <Select
              labelId="wallet-purpose-label"
              id="purpose"
              name="purpose"
              label="Purpose" 
              value={this.state.purpose}
              onChange={this.handleChange} required
            >
              <MenuItem value='default'>Default</MenuItem>
              <MenuItem value='secondary'>Secondary</MenuItem>
            </Select>
          </FormControl>

          <br/><br/>
          If you provide only a mnemonic, the wallet will be generated from the mnemonic:
          <br/>
          
          <TextField
            id="mnemonic"
            label="Mnemonic"
            name="mnemonic"
            value={this.state.mnemonic}
            onChange={this.handleChange}
            autoComplete="true"
          />
          <br/><br/>


          
          <TextField
            id="public-key"
            label="Public Key"
            name="publicKey"
            value={this.state.publicKey}
            onChange={this.handleChange}
            autoComplete="true"
          />

          <br/><br/>
          
          <TextField
            id="private-key"
            label="Private Key"
            name="privateKey"
            value={this.state.privateKey}
            onChange={this.handleChange}
            autoComplete="true"
          />
          
          <TextField
            id="address"
            label="Address"
            name="address"
            value={this.state.address}
            onChange={this.handleChange}
            autoComplete="true"
          />

          <br/><br/>

          <Button id="wallet-submit" type="submit" variant="contained">Save</Button>

        </form>
      
      </div>
    );
  }


}

export default WalletEdit;

//          <FormControlLabel control={<Checkbox name="default" value={this.state.default} onChange={this.handleChange}/>} label="Default" />
