
import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography} from '@mui/material';

import {getAuth} from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";
import {getStorage,ref,uploadBytesResumable,getDownloadURL,} from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';
initializeApp(getFirebaseConfig());

class ProductEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getProduct = this.getProduct.bind(this);
    this.saveProduct = this.saveProduct.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.fileInput = React.createRef();


  }

  getDefaultState() {
    return {
      name: '', 
      imageUrl: '', 
      sku: '', 
      attrDisplay1: '', attrType1: '', attrValue1: '',
      attrDisplay2: '', attrType2: '', attrValue2: '',
      attrDisplay3: '', attrType3: '', attrValue3: '',
      attrDisplay4: '', attrType4: '', attrValue4: '',
      attrDisplay5: '', attrType5: '', attrValue5: '',
      productLineId : '', 
      link: '',
      product : null
    }
  }

  async componentDidMount() {
    this.getProduct();

  }

  componentWillUnmount() {

  }

  handleChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(`setting state to ${name} = ${value}`)

    this.setState({
      [name]: value
    });

  }

  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    const imageFile = this.fileInput.current.files[0];

    var imageUrl;

    if (imageFile != null) {
      imageUrl = await this.saveImageMessage(imageFile);
      
      //this.setState({imageUrl: imageUrl});

      this.setState({imageUrl: imageUrl}, () => {
        //console.log(`set to state using imageUrl: ${imageUrl}`);
        //console.log(`set to state imageUrl: ${this.state.imageUrl}`);
        this.saveProduct();    
      }); 

    } else 
      this.saveProduct();

  }

  async getProduct() {

    if (this.props.productLineId != null) {
      this.setState({productLineId : this.props.productLineId});
    }

    if (this.props.id != null) {
    
      console.log('getting product with id ' + this.props.id);

      const docRef = doc(getFirestore(), "products", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        productLineId: result.data().productLineId,
        product: result.data()
      });
    } else if (this.props.data != null) {
      this.setState({ 
        productLineId: this.props.data.productLineId,
        product: this.props.data
      });
    }

  }


  async saveProduct() {
    var owner = getAuth().currentUser.uid;
    var productLineId = this.state.productLineId;
    var pName = this.state.name;
    var pImage = this.state.imageUrl;
    var pSku = this.state.sku;
    var pAttrDisplay1 = this.state.attrDisplay1;
    var pAttrType1 = this.state.attrType1;
    var pAttrVal1 = this.state.attrValue1;
    var pLink = this.state.link;


    console.log(`about to save a product with ${pName}, ${pSku}, ${this.state.imageUrl}`);

    let flag1  = this.state.attrValue1 !== '';
    let flag2  = this.state.attrValue2 !== '';
    let flag3  = this.state.attrValue3 !== '';
    let flag4  = this.state.attrValue4 !== '';
    let flag5  = this.state.attrValue5 !== '';

    let prodData = {
      owner: owner,
      productLineId: productLineId,
      productImageUrl: pImage,
      productName: pName,
      productSku: pSku,
      productLink: pLink,
      attributes: [],
      timestamp: serverTimestamp()
    };

    if (flag1) {
      prodData.attributes[0] = {
        ...(pAttrDisplay1 !== '' && {display_type: pAttrDisplay1}),
        trait_type: pAttrType1,
        value: pAttrVal1
      };
    }

    if (flag2) {
      prodData.attributes[1] = {
        ...(this.state.attrDisplay2 !== '' && {display_type: this.state.attrDisplay2}),
        trait_type: this.state.attrType2,
        value: this.state.attrValue2
      };
    }

    if (flag3) {
      prodData.attributes[2] = {
        ...(this.state.attrDisplay3 !== '' && {display_type: this.state.attrDisplay3}),
        trait_type: this.state.attrType3,
        value: this.state.attrValue3
      };
    }

    if (flag4) {
      prodData.attributes[3] = {
        ...(this.state.attrDisplay4 !== '' && {display_type: this.state.attrDisplay4}),
        trait_type: this.state.attrType4,
        value: this.state.attrValue4
      };
    }

    if (flag5) {
      prodData.attributes[4] = {
        ...(this.state.attrDisplay5 !== '' && {display_type: this.state.attrDisplay5}),
        trait_type: this.state.attrType5,
        value: this.state.attrValue5
      };
    }

    console.log('saving product ' + JSON.stringify(prodData));

    const prodColRef = collection(getFirestore(), `productLines/${productLineId}/products`);
    const docRef = await addDoc(prodColRef, prodData);

    console.log(`product saved: ${docRef.id}`);

    this.setState(this.getDefaultState());
      
  }

  async saveImageMessage(file) {
    try {
      console.log('about to upload file ' + file.name);

      // Upload the image to Cloud Storage.
      // TODO: the 1 char here should be changed to the product id when its created
      const filePath = `${getAuth().currentUser.uid}/1/${file.name}`;
      console.info('uploading to path: ', filePath);
      const newImageRef = ref(getStorage(), filePath);
      const fileSnapshot = await uploadBytesResumable(newImageRef, file);
      
      // 3 - Generate a public URL for the file.
      const publicImageUrl = await getDownloadURL(newImageRef);

      const fullPath = fileSnapshot.metadata.fullPath;
  
      console.log('uploaded file: ' + publicImageUrl);
      console.log('image full path: ' + fullPath);

      return publicImageUrl;

    } catch (error) {
      console.error('There was an error uploading a file to Cloud Storage:', error);
      return '';
    }
  }

  render() {

    let productLineIdInput;
    if (this.props.productLineId) {
      productLineIdInput = 
        <input type="hidden" id="product-line-id" name="productLineId" value={this.state.productLineId} onChange={this.handleChange}/>
    } else {
      productLineIdInput = 
        <TextField
          id="product-line-id"
          label="Product Line ID"
          name="productLineId"
          value={this.state.productLineId}
          onChange={this.handleChange}
          autoComplete="true" required
        />
    }

    return (
      <div className="ProductEdit">
      <br/>
      <Typography variant="h4">Add Product</Typography>
      <br/>

      <form id="product-form"  onSubmit={this.handleSubmit}>
        {productLineIdInput}
        
        <TextField
          id="product-sku"
          label="SKU"
          name="sku"
          value={this.state.sku}
          onChange={this.handleChange}
          autoComplete="true" required
        />
        <TextField
          id="product-name"
          label="Name"
          name="name"
          value={this.state.name}
          onChange={this.handleChange}
          autoComplete="true" required
        />
        <br/><br/>

        <TextField
          id="product-link"
          label="External Link"
          name="link"
          value={this.state.link}
          onChange={this.handleChange}
          autoComplete="true"
        />
        <br/><br/>

        One of the following:<br/>
        
        <span className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            
            <input type="file" ref={this.fileInput}  accept="image/*" className="mdl-textfield__input"/>
            <label className="mdl-textfield__label" htmlFor="product-image">Product Image</label>
        </span>
        
        <TextField
          id="product-image-url"
          label="Product Image URL"
          name="imageUrl"
          value={this.state.imageUrl}
          onChange={this.handleChange}
          autoComplete="true"
        />
        
        <br/><br/>

        Attribute 1 <br/>
        <TextField
          id="product-attr-1-display"
          label="Display Type"
          name="attrDisplay1"
          value={this.state.attrDisplay1}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-type-1"
          label="Type"
          name="attrType1"
          value={this.state.attrType1}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-value-1"
          label="Value"
          name="attrValue1"
          value={this.state.attrValue1}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <br/><br/>

        Attribute 2 <br/>
        <TextField
          id="product-attr-2-display"
          label="Display Type"
          name="attrDisplay2"
          value={this.state.attrDisplay2}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-type-2"
          label="Type"
          name="attrType2"
          value={this.state.attrType2}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-value-2"
          label="Value"
          name="attrValue2"
          value={this.state.attrValue2}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <br/><br/>
        Attribute 3 <br/>
        <TextField
          id="product-attr-3-display"
          label="Display Type"
          name="attrDisplay3"
          value={this.state.attrDisplay3}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-type-3"
          label="Type"
          name="attrType3"
          value={this.state.attrType3}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-value-3"
          label="Value"
          name="attrValue3"
          value={this.state.attrValue3}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <br/><br/>
        Attribute 4 <br/>
        <TextField
          id="product-attr-4-display"
          label="Display Type"
          name="attrDisplay4"
          value={this.state.attrDisplay4}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-type-4"
          label="Type"
          name="attrType4"
          value={this.state.attrType4}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-value-4"
          label="Value"
          name="attrValue4"
          value={this.state.attrValue4}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <br/><br/>
        Attribute 5 <br/>
        <TextField
          id="product-attr-5-display"
          label="Display Type"
          name="attrDisplay5"
          value={this.state.attrDisplay5}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-type-5"
          label="Type"
          name="attrType5"
          value={this.state.attrType5}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <TextField
          id="product-attr-value-5"
          label="Value"
          name="attrValue5"
          value={this.state.attrValue5}
          onChange={this.handleChange}
          autoComplete="true" 
        />
        <br/><br/>

        <Button id="product-line-submit" type="submit" variant="contained">Save Product</Button>


      </form>
      
      </div>
    );
  }


}

export default ProductEdit;

/*

    <form id="product-image-form" onSubmit={this.handleSubmit}>
      Product image: 
      
      <input id="mediaCapture" type="file" accept="image/*" capture="camera"/>
      <button id="submitImage" title="Add a product image" className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--amber-400 mdl-color-text--white">
        <i className="material-icons">image</i>
      </button>
      <br/>
    </form>

    */