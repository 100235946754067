import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, Grid} from '@mui/material';

import {
    getAuth,
    createUserWithEmailAndPassword,
    updateProfile,
  } from 'firebase/auth';

import { getFirebaseConfig, isUserSignedIn } from '../firebase_config.js';
import { Link, Navigate } from 'react-router-dom';

class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getDefaultState() {
    return {displayName: '', email: '', password: ''}
  }

  componentDidMount() {
  }

  componentWillUnmount() {

  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

  render() {

    if (isUserSignedIn()) {
      return <Navigate to="/" replace={true} />
    }

     return (

        <form id="register-form" onSubmit={this.handleSubmit}>

            <Typography variant='h2' marginTop={4} marginBottom={4}>Register</Typography>

            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
              Please provide some minimal information to create an account.
            </Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
              Learn more about registering in our <Link to='/about/registration'>about section</Link>.
            </Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
            If you already have an account, please <Link to='/login'>login</Link> instead.
            </Typography>

            <Grid container spacing={{ xs: 2, md: 3 }} direction="column" justifyContent="space-between" xs={12} sm={4}>
              <Grid item>
                <TextField
                  id="register-display"
                  label="Display Name"
                  name="displayName"
                  value={this.state.displayName}
                  onChange={this.handleChange}
                  autoComplete="true" required fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  id="register-email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete="true" required fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  id="register-password"
                  label="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  autoComplete="current-password" required fullWidth
                />
              </Grid>
              <Grid item>
                <Button id="submit" type="submit" variant="contained">Register</Button>
              </Grid>
            </Grid>
          </form>
      );
  }


  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    /*
    grecaptcha.ready(function() {
      grecaptcha.execute('', {action: 'submit'}).then(function(token) {
          // Add your logic to submit to your backend server here.
      });
    });
    */
        
    var display = this.state.displayName;
    var email = this.state.email;
    var pwd = this.state.password;
  
    console.log('going to register user with displayName ' + display + ' and email ' + email);
  
    if (email && pwd
         && !isUserSignedIn()) {
      await this.createUser(display, email, pwd);
    }
  }


async createUser(display, email, password) {


    try {
      const userCredential = await createUserWithEmailAndPassword(getAuth(), email, password);

      // Signed in 
      const user = userCredential.user;
      console.log('created user: ' + user.uid);

      const curUser = getAuth().currentUser;

        updateProfile(curUser, {displayName: display}).then(() => {
            //console.log('profile display name set: ' + display);
        }).catch((error) => {
            console.error('error setting display: ' + error.code + ': ' + error.message);
        });


        this.setState(this.getDefaultState());
    
    } catch(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      
      console.error('error registering: ' + errorCode + ': ' + errorMessage);

      this.setState({error: 'error registering: ' + errorCode + ': ' + errorMessage});
    }
  
  }

}

export default Register;
