import { Typography } from "@mui/material";

function AboutBlockchain(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Overview of the blockchain and related topics</Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='what-is-the-blockchain'>What is the blockchain?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
                The blockchain is a digital ledger technology that enables the creation of a decentralized, transparent, 
                and immutable record of transactions. In essence, it is a database that stores information in blocks that 
                are linked together in chronological order, creating a chain of blocks. Each block contains a cryptographic 
                hash of the previous block, which ensures the integrity of the chain and prevents any tampering with the data.<br/>
                
                The blockchain technology was originally created as the underlying technology for the digital currency Bitcoin, 
                but its potential applications go far beyond the realm of finance. The blockchain can be used to record any type 
                of transaction or data, such as property ownership, supply chain management, voting records, and more. Because it 
                is decentralized and transparent, it has the potential to increase trust, reduce fraud, and lower costs in many industries.<br/>
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='what-is-ethereum'>What is Ethereum?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Ethereum is a decentralized, open-source blockchain network that allows developers to build decentralized applications (dApps) and smart contracts. It was created by Vitalik Buterin in 2015 and is currently the second-largest cryptocurrency by market capitalization, after Bitcoin.<br/>

                Ethereum's unique feature is its ability to run smart contracts. This allows developers to create custom applications that can execute programmable transactions and self-enforce business rules. Ethereum also allows for the creation of new cryptocurrencies, called ERC-20 tokens, which can be used for a variety of purposes, such as fundraising, reward programs, and in-app currencies.<br/>

                Ethereum has its own cryptocurrency called Ether (ETH), which is used to pay for transactions and computational services on the network. It is also used as a store of value, similar to Bitcoin.<br/>

                Ethereum's ecosystem is constantly evolving, with new applications and use cases being developed by a global community of developers and entrepreneurs. It has the potential to transform many industries by providing a more decentralized, transparent, and secure way to conduct transactions and exchange value.<br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='what-is-ether'>What is Ether?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Ether (ETH) is the native cryptocurrency of the Ethereum blockchain network. It is a digital asset that enables peer-to-peer transactions and smart contract functionality on the Ethereum platform.<br/>

                Ether serves multiple functions within the Ethereum ecosystem. Firstly, it is used as a medium of exchange for transactions and payments within the Ethereum network. Secondly, it is used to pay for transaction fees and computational services that are needed to run decentralized applications (DApps) on the Ethereum platform. Thirdly, ether is used as a store of value and a speculative investment asset, similar to other cryptocurrencies such as Bitcoin.<br/>

                Unlike Bitcoin, which is primarily used as a currency, Ether has a broader range of use cases due to its ability to power smart contracts. These smart contracts are self-executing computer programs that automatically enforce the rules and conditions of an agreement between two or more parties. This functionality enables the creation of decentralized applications (DApps) that can run on the Ethereum blockchain.<br/>

                Overall, Ether is a fundamental component of the Ethereum ecosystem, and it plays a crucial role in enabling decentralized applications and transactions on the Ethereum network.<br/>
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='smart-contract'>What is a Smart Contract?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                A smart contract is a self-executing contract with the terms of the agreement between buyer and seller being directly 
                written into lines of code. The code and the agreements contained therein exist on a blockchain network, 
                and automatically execute when certain conditions are met.<br/>

                Smart contracts are designed to facilitate, verify, and enforce the negotiation and performance of a contract between two parties. 
                They can be programmed to automatically trigger specific actions when certain conditions are met, such as releasing payment when goods 
                are received, or triggering a refund if certain conditions are not met.<br/>

                Smart contracts eliminate the need for intermediaries, such as lawyers or banks, to execute a contract. 
                They also reduce the risk of fraud or errors by ensuring that the terms of the contract are clearly defined 
                and automatically enforced. Smart contracts have a wide range of potential applications, from financial transactions 
                and supply chain management to voting systems and property transfers.<br/>
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='what-are-nfts'>What are NFTs?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                NFTs, or Non-Fungible Tokens, are digital assets that represent ownership of a unique item or piece of content, such as art, music, videos, virtual real estate, and more. Each NFT is a one-of-a-kind item that cannot be replicated or exchanged for something else of equal value, unlike traditional currencies or cryptocurrencies, which are fungible.<br/>

                NFTs are stored on a blockchain, a decentralized digital ledger that records transactions in a secure and transparent manner. This allows for the ownership and transfer of NFTs to be tracked and verified, providing a level of authenticity and scarcity that can increase their value.<br/>

                NFTs have gained popularity in recent years as a new way for artists, musicians, and creators to monetize their digital creations and for collectors to invest in unique digital assets. However, there is also some debate about the environmental impact of NFTs and their long-term value.<br/>
            </Typography>
            
            <Typography variant='h4' marginTop={4} marginBottom={4} id='online-wallet'>What is an Online Wallet?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                An online wallet, also known as a digital wallet or cryptocurrency wallet, is a software program that allows you to securely store, manage, and transfer your digital assets such as Bitcoin, Ethereum, or other cryptocurrencies.<br/>

                Crypto wallets come in various forms, including desktop software, mobile apps, hardware devices, or web-based services. Each wallet has a unique public address and a private key, which are used to send and receive cryptocurrencies.<br/>

                The private key is a secret code that provides access to your funds, so it's essential to keep it safe and secure. Many crypto wallets offer additional security features such as two-factor authentication (2FA) or multi-signature functionality to protect your digital assets from theft or unauthorized access.<br/>

                When using a crypto wallet, it's crucial to understand the risks involved, such as the possibility of losing your private key or falling victim to phishing attacks. Therefore, it's important to choose a reputable wallet provider and follow best security practices to keep your digital assets safe.<br/>
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='what-is-a-crypto-exchange'>What is a Crypto Exchange?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  

                A crypto exchange allows you to convert fiat currency to crypto currency and vice versa. 
                A crypto exchange uses a crypto wallet (also referred to as an online wallet) to store crypto currency. <br/>

                An example of a reputable crypto exchange is coinbase. You can register with coinbase and use the exchange to purchase crypto currency and store that crypto currency in your crypto wallet. 
                You can then use the crypto wallet to pay for various activities such as deploying smart contracts or minting NFTs.<br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>How Do I Get Ether into my Online Wallet?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                This is most easily achieved using a crypto exchange which enables the purchase of crypto currency using fiat currency. <br/>
                Once the crypto has been purchased on the exchange and transferred to an online wallet, that wallet can be connected to tokemax to pay for activities involved in the blockchain.
            </Typography>


        </>
    )
}  

export default AboutBlockchain;
