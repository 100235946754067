
import React from 'react';

import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';

import { Typography, TableRow, TableCell, Tooltip, Link, Box} from '@mui/material';

initializeApp(getFirebaseConfig());

class ProductView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {product: null};

    this.getProduct = this.getProduct.bind(this);

  }

  async componentDidMount() {
    this.getProduct();

  }

  componentWillUnmount() {

  }

  async getProduct() {

    if (this.props.id != null) {
    
      console.log('getting product with id ' + this.props.id);

      const docRef = doc(getFirestore(), "products", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        product: result.data()
      });
    } else if (this.props.data != null) {
      this.setState({ 
        product: this.props.data
      });
    }

  }


  render() {

    let product;
    if (this.state.product != null) {

      let productImgUrl;
      if (this.state.product.productImageUrl) {
        productImgUrl = <Box
              component="img"
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Product Image"
              src={this.state.product.productImageUrl}
            />
      }

      product = 
      <TableRow
        key={this.state.product.productSku}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        
        <TableCell component="th" scope="row">{this.state.product.productSku}</TableCell>
        <TableCell align="left">{this.state.product.productName}</TableCell>
        <TableCell align="left">
          
          {productImgUrl}
          
        </TableCell>
      </TableRow>
    } 

//    console.log('about to render with product var ' + product);

    return (
      <>{product} </>
      
    );
  }


}

export default ProductView;
