import React from 'react';

import Order from './Order';
import OrderEdit from './OrderEdit';

import {getAuth} from 'firebase/auth';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp, query, where, getDocs,} from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';

import { Typography, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableHead, Tooltip} from '@mui/material';

const firebaseApp = initializeApp(getFirebaseConfig());
const auth = getAuth(firebaseApp);


class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(null);


    this.getOrders = this.getOrders.bind(this);
      
    this.createAuthHook();

  }

  createAuthHook() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const uid = user.uid;

        this.setState(this.getDefaultState(user));

        this.getOrders();

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      orders: []
    }
  }

  async componentDidMount() {
    if (this.isUserSignedIn())
        this.getOrders(getAuth().currentUser.uid);

  }

  componentWillUnmount() {

  }


  async getOrders() {

    if (!this.isUserSignedIn()) {
      return;
    }

    const owner = auth.currentUser.uid;
    console.log('getting orders for owner ' + owner);

    const q = query(collection(getFirestore(), "orders"), where("owner", "==", owner));    

    const querySnapshot = await getDocs(q);
    let plData = querySnapshot.docs.map(doc => doc);


    /*plData.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });*/

    this.setState({ 
      orders: plData ,
    });
  }


  render() {
    const listItems = this.state.orders.map((plDoc) =>
      <Order key={plDoc.id} data={plDoc}/>
    );

    let plEdit;
    //if (this.state.productLineEditDisplay) {
    if (true) {
      plEdit = <OrderEdit/>
    }

    let table;
    if (this.state.currentUser != null) {
      table = 

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Orders" >
          <TableHead >
            <TableRow>
              <TableCell/>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Order Reference</TableCell>
              <TableCell align="left">Order Date</TableCell>
              <TableCell align="left">Order amount</TableCell>
              <TableCell align="left">Merchant</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    }

    return (
      
      <div className="Orders">
        <br/>
        <Typography variant="h2">Your Orders</Typography>
        <p>
          Your number of Orders: {this.state.orders.length}
        </p>

        {plEdit}

        {table}
        
        <br/>
      </div>
    );
  }


  isUserSignedIn() {
    var result = !!getAuth().currentUser;
    //console.log('isUserSignedIn: ' + result);
  return result;
  }

}

export default Orders;
