import { Link, Typography } from "@mui/material";
import { HashLink } from 'react-router-hash-link';


function AboutProfile(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Managing Your Profile</Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>Overview</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Your profile provides access to details about online wallets and digital collectibles.<br/>
                To find out more about online wallets, learn more <HashLink to='/about/blockchain#online-wallet'>here</HashLink>. <br/>

                To view your profile, click <Link href='/profile'>here</Link>.
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>Create an Online Wallet</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Create a wallet from the profile page using the Edit Wallet form.<br/>
                See the View your online wallets section below for details about the individual fields you can define for a wallet.
                There are a number of ways to create an online wallet:<br/>
            </Typography>
            <ol>
                <li>Register an existing wallet - This option allows youto provide details of an existing wallet. This is ideal when you have a wallet that already contains eth that you wish to use to deploy contracts for your products or if you are paying for a digital collectible.</li>
                <li>Create a random wallet - a mnemonic, private key, public key and address will be generated for you.</li>
                <li>Create a wallet from a mnemonic - if you provide the mnemonic for your wallet, the public/private key and address will be generated from that. Suitable for registering an existing wallet with tokemax. </li>
            </ol>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='view-your-online-wallet'>View Your Online Wallets</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                You can view all the wallets that you have created and linked to your account. <br/>
                You can access details about your wallet including
            </Typography>
            <ul>
                <li><strong>Label</strong> - a label you can assign to help you to remember how its used</li>
                <li><strong>Purpose</strong> - determines how a walllet will be used for certain actions. The default wallet is used for creating contracts (when a product line is deployed) or for owning digital collectibles (when a purchase is made). Our roadmap has a feature to enable specific purposes for specific wallet actions. </li>
                <li><strong>Public Key</strong> - the public key associated with your online wallet. Used to prove ownership of the wallet on the blockchain</li>
                <li><strong>Private Key</strong> - the private key associated with your online wallet. Used to access the wallet contents such as eth and digital collectibles.</li>
                <li><strong>Menmonic</strong> - A special phrase used to unlock the wallet instead of a private key. </li>
                <li><strong>Address</strong> - The address of the online wallet on the blockchain. Click on the address to see more details about the specific wallet.</li>
            </ul>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Clicking on the wallet address will taken you to a detailed view of your wallet which will show any tokens stored in your wallet if there are any.
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='wallet-types'>Wallet Types</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Wallet types are denoted by the purpose attribute of the wallet. <br/>
                The default wallet will be used to deploy contracts or pay for digital collectibles.<br/>
                While you can have multiple wallets registered, there must be a single default wallet. <br/>
                If you do not have a default wallet, these actions will fail. <br/>
                The default wallet will need to have adequate eth associated with it in order to pay for actions you undertake. <br/>
                There is a feature in our roadmap to enable different wallets to be used for different purposes, such as a wallet just for contract deployment and another for holding digital collectibles.
            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4}>Wallet Best Practices</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Wallets added to tokemax typically require the private key to be provided so that actions can be performed using that wallet, by the tokemax system. <br/>
                For this reason, it is suggested that the wallets you register with tokemax contain only the minimum amount of eth needed to perform the actions you want to. <br/>
                Similarly with wallets used to hold digital collectibles as NFTs - it is suggested that NFTs are transferred to external wallets after purchasing. <br/>
                There is a feature on our roadmap to enable transfers of NFTs to different wallets. Until that feature is available, it is possible to use existing tooling in the blockchain ecosystem.
            </Typography>
            
            <Typography variant='h4' marginTop={4} marginBottom={4}>Delete an Online Wallet</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                Use the delete action on the view wallets list to delete a wallet.<br/>
                Deleting a wallet will remove it from the tokemax system but it will not remove any of the contents, such as digital collectibles, from the underlying blockchain.<br/>
                If you delete a wallet and you do not have access to the private key or mnemonic, you will not be able to transfor or prove ownership of your digital collectibles.<br/>
            </Typography>
        </>
    )
}  

export default AboutProfile;
