
import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, Alert} from '@mui/material';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";
//import {getStorage,ref,uploadBytesResumable,getDownloadURL,} from 'firebase/storage';

import {getAuth} from 'firebase/auth';
//import { initializeApp } from 'firebase/app';
import { getFirebaseConfig, auth } from '../firebase_config.js';
//initializeApp(getFirebaseConfig());


class OrderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getOrder = this.getOrder.bind(this);
    this.saveOrder = this.saveOrder.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  getDefaultState() {
    return {
      date: '', orderId: '', amount: '', productSku: '', quantity: '', date: '', merchant: '', email: '',
      productSku1: '', quantity1: '',productAmount1: '',
      productSku2: '', quantity2: '',productAmount2: '',
      productSku3: '', quantity3: '',productAmount3: '',
  
    }
  }

  async componentDidMount() {
    this.getOrder();

  }

  componentWillUnmount() {

  }

  handleChange(event) {
    //this.setState({value: event.target.value});

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(`setting state to ${name} = ${value}`)

    this.setState({
      [name]: value
    });

  }

  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    this.saveOrder();

  }

  async getOrder() {

    if (this.props.id != null) {
    
      console.log('getting order with id ' + this.props.id);

      const docRef = doc(getFirestore(), "orders", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        orderId: docRef.id,
        order: result.data()
      });
    } else if (this.props.data != null) {
      this.setState({ 
        orderId: this.props.orderId,
        order: this.props.data
      });
    }

  }

  async saveOrder() {

    if (!getAuth().currentUser) {
      this.errorState('please login')
      return;
    }

    try {
  
      var owner = getAuth().currentUser.uid;
      var oId = this.state.orderId;
      //var pSku = this.state.productSku;
      var amt = this.state.amount;
      var date = this.state.date;
      var merchant = this.state.merchant;
      var email = this.state.email;
      
      console.log(`about to save an order with ${owner}, ${oId}, ${amt}, ${date}, ${merchant}`);
  
      const flag1  = this.state.productSku1 !== '';
      const flag2  = this.state.productSku2 !== '';
      const flag3  = this.state.productSku3 !== '';

      const hasEmail  = this.state.hasEmail !== '';

      if (!flag1 && !flag2 && !flag3) {
        this.errorState(`You need to define at least one line item in the order`);
        return;
      }

      var orderData = {
        owner: owner,
        orderId: oId,
        orderAmount: amt,
        orderDate: date,
        sellingMerchant: merchant,
        productSku: this.state.productSku1,
        lineItems: [],
        createTime: serverTimestamp()
      }

      if (flag1) {
        orderData.lineItems[0] = {
          productSku: this.state.productSku1,
          ...(this.state.quantity1 !== '' && {quantity: this.state.quantity1}),
          ...(this.state.productAmount1 !== '' && {productAmount: this.state.productAmount1})
          };
      }

      if (flag2) {
        orderData.lineItems[1] = {
          productSku: this.state.productSku2,
          ...(this.state.quantity2 !== '' && {quantity: this.state.quantity2}),
          ...(this.state.productAmount2 !== '' && {productAmount: this.state.productAmount2})
          };
      }

      if (flag3) {
        orderData.lineItems[2] = {
          productSku: this.state.productSku3,
          ...(this.state.quantity3 !== '' && {quantity: this.state.quantity3}),
          ...(this.state.productAmount3 !== '' && {productAmount: this.state.productAmount3})
          };
      }

      if (hasEmail) {
        orderData.email = email;
      }

      console.log(`about to save order: ${JSON.stringify(orderData)}`);

      var docRef = await addDoc(collection(getFirestore(), 'orders'), orderData);
  
      console.log(`order saved: ${docRef.id}`)
      this.messageState(`order saved: ${docRef.id}`)

      this.setState(this.getDefaultState());
    }
    catch (error) {
      console.error('Error saving new order', error);
      this.errorState(`Error saving new order: ${error}`)
    }   
  }


  errorState(message) {
    this.setState(
      {
        message: message, 
        severity: 'error'
      }
    );
  }

  messageState(message) {
    this.setState(
      {
        message: message, 
        severity: 'success'
      }
    );
  }

  render() {

    let message;
    if (this.state.message) {
      message = 
      <><Alert severity={this.state.severity}>{this.state.message}</Alert><br/></>
    }

    return (
      <div className="OrderEdit">

        <Typography variant="h4">Order</Typography>
        <br/><br/>

        {message}

        <form id="order-form" onSubmit={this.handleSubmit}>

          <TextField
            id="order-id"
            label="Order Reference"
            name="orderId"
            value={this.state.orderId}
            onChange={this.handleChange}
            autoComplete="true" required
          />

          <TextField
            id="order-date"
            label="Date"
            name="date"
            value={this.state.date}
            onChange={this.handleChange}
            autoComplete="true" required
          />

          <TextField
            id="order-amount"
            label="Amount"
            name="amount"
            value={this.state.amount}
            onChange={this.handleChange}
            autoComplete="true" required
          />

          <TextField
            id="order-merchant"
            label="Merchant"
            name="merchant"
            value={this.state.merchant}
            onChange={this.handleChange}
            autoComplete="true"
          />
          <br/>
          

          <TextField
            id="order-consumer"
            label="Buyer email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            autoComplete="true"
          />

          <br/><br/>

          <Typography variant="h5">Order Line Items</Typography>
          <br/><br/>
          
          <Typography variant="h6">1:</Typography>
          <TextField
            id="order-product-sku"
            label="Product SKU"
            name="productSku1"
            value={this.state.productSku1}
            onChange={this.handleChange}
            autoComplete="true" 
          />
          
          <TextField
            id="order-product-quantity"
            label="Product Quantity"
            name="quantity1"
            value={this.state.quantity1}
            onChange={this.handleChange}
            autoComplete="true" 
          />
          
          <TextField
            id="order-product-quantity"
            label="Product Cost"
            name="productAmount1"
            value={this.state.productAmount1}
            onChange={this.handleChange}
            autoComplete="true" 
          />

          <br/><br/>

          <Typography variant="h6">2:</Typography>
          <TextField
            id="order-product-sku"
            label="Product SKU"
            name="productSku2"
            value={this.state.productSku2}
            onChange={this.handleChange}
            autoComplete="true" 
          />
          
          <TextField
            id="order-product-quantity"
            label="Product Quantity"
            name="quantity2"
            value={this.state.quantity2}
            onChange={this.handleChange}
            autoComplete="true" 
          />
          
          <TextField
            id="order-product-quantity"
            label="Product Cost"
            name="productAmount2"
            value={this.state.productAmount2}
            onChange={this.handleChange}
            autoComplete="true" 
          />

          <br/><br/>

          <Typography variant="h6">3:</Typography>
          <TextField
            id="order-product-sku"
            label="Product SKU"
            name="productSku3"
            value={this.state.productSku3}
            onChange={this.handleChange}
            autoComplete="true" 
          />

          <TextField
            id="order-product-quantity"
            label="Product Quantity"
            name="quantity3"
            value={this.state.quantity3}
            onChange={this.handleChange}
            autoComplete="true" 
          />

          <TextField
            id="order-product-quantity"
            label="Product Cost"
            name="productAmount3"
            value={this.state.productAmount3}
            onChange={this.handleChange}
            autoComplete="true" 
          />

          <br/><br/>

          <Button id="order-submit" type="submit" variant="contained">Save</Button>

        </form>
      
      </div>
    );
  }


}

export default OrderEdit;
