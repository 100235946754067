
import React from 'react';

import ProductLine from './ProductLine';
import ProductLineEdit from './ProductLineEdit';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { isUserSignedIn, app, auth } from '../firebase_config.js';

import { Collapse, IconButton, Typography, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableHead, Tooltip} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


class ProductLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(null);


    this.handleClick = this.handleClick.bind(this);
    this.getProductLines = this.getProductLines.bind(this);
      

    this.createAuthHook();

  }

  createAuthHook() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const uid = user.uid;

        this.setState(this.getDefaultState(user));

        this.getProductLines();

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      owner: null,
      productLines: [], 
      productLineEditDisplay : false, 
    }

  }

  async componentDidMount() {
    this.getProductLines();
  }

  componentWillUnmount() {

  }

  async getProductLines() {
    
    if (!isUserSignedIn() || !auth.currentUser) {
      return;
    }

    const owner = auth.currentUser.uid;
    
    console.log('getting product lines for owner ' + owner);

    const q = query(collection(getFirestore(), "productLines"), where("owner", "==", owner));    

    const querySnapshot = await getDocs(q);
    let plData = querySnapshot.docs.map(doc => doc);


    /*plData.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });*/


    this.setState({ 
      productLines: plData ,
    });
  }
 

  handleClick() {
    //console.log('in handle click');
    this.setState(prevState => ({
      productLineEditDisplay: !prevState.productLineEditDisplay
    }));
  }


  render() {

    const listItems = this.state.productLines.map((plDoc) =>
      <ProductLine key={plDoc.id} data={plDoc}/>
    );

    let plEdit;
    //if (this.state.productLineEditDisplay) {
    if (true) {
      plEdit = <ProductLineEdit/>
    }


    let table;
    if (this.state.currentUser != null) {
      table = 

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Orders" >
          <TableHead >
            <TableRow>
              <TableCell />
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Product Line Symbol</TableCell>
              <TableCell align="left">Product Line Name</TableCell>
              <TableCell align="left">Product Line Description</TableCell>
              <TableCell align="left">Product Line Contract Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    }

    return (
      
      <div className="ProductLines">
      <br/>
      
          <Typography variant="h2">Product Lines</Typography>
          <p>
            Your number of Product Lines: {this.state.productLines.length}
          </p>

          {/*plEdit*/}

          {table}
          
          <br/>
      </div>
    );

  }

  /*
          <button onClick={this.handleClick}>
            {this.state.productLineEditDisplay ? 'Hide Edit' : 'Show edit'}
          </button>
*/

/*  isUserSignedIn() {
    var result = !!auth.currentUser;
    //console.log('isUserSignedIn: ' + result);
    return result;
  }*/
}

export default ProductLines;
