import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function AboutAuthenticity(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Prove Authenticity</Typography>

            <Typography marginTop={2} marginBottom={2} lineHeight={2}>
                There are many occasions when you want to prove that you have purchased something. 
                It may be as important as making a formal claim or as fun as showing off a purchase to an online community. <br/>

                When a purchase of a physical item is captured as a digital collectible that is stored in your online wallet, 
                you can use that collectible to prove the physical item was purchased by you and also to trace the 
                history of the item if it has been purhcased and sold numerous times. <br/>


                Often when an expensive item such as exclusive wines or other luxury goods are re-sold, it is hard to verify 
                that the item being sold is an original and worthy of the price being asked. <br/>
                
                    
                A digital collectible tied specifically to that exclusive item that you purchased, to your online wallet and to the business that 
                sold the item can provide compelling guarantees of authenticity. <br/>

                
                The digital collectible contains information about the purchase including the time, amount, item identifiers such as 
                serial numbers as well as other customer details that the buyer and seller can choose to add. <br/>

                Digital collectibles are stored as NFTs on the <HashLink to='/about/blockchain#what-is-the-blockchain'>blockchain</HashLink> meaning they can be viewed and understood by a 
                large number of existing community platforms and tools. <br/>

                <Link to='/about/collectibles'>Learn More</Link> about digitial collectibles.

            </Typography>

        </>
    )
}  

export default AboutAuthenticity;
