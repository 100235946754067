
import React from 'react';
import { Typography, TableRow, TableCell, Tooltip, Link} from '@mui/material';

import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';

initializeApp(getFirebaseConfig());

//import { isUserSignedIn } from './auth.js'; 

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {purchaseId: null, purchase: null};

    this.getPurchase = this.getPurchase.bind(this);

  }

  async componentDidMount() {
    this.getPurchase();
  }
  

  componentWillUnmount() {

  }

  async getPurchase() {
    

    if (this.props.data != null) {
    //console.log('loading purchase data into state from ' + JSON.stringify(this.props.data.data()));

      let purchase = this.props.data.data();

      let tokenTs;
        if (purchase.token!= null && purchase.token.timestamp != null)
          tokenTs = purchase.token.timestamp.toDate().toDateString();

      this.setState({
          purchaseId : this.props.data.id,
          purchase : purchase,
          orderId : purchase.orderId,
          productSku : purchase.productSku,
          productSerialNo : purchase.productSerialNo,
          purchaseDate: purchase.timestamp != null ? purchase.timestamp.toDate().toDateString() : null,
          tokenTs : tokenTs,
          metadataUrl : purchase.token != null ? purchase.token.metadataUrl : null,
          tokenId : purchase.token != null ? purchase.token.tokenId : null,
          contractAddress : purchase.token != null ? purchase.token.contractAddress : null,
          mintTxHash : purchase.token != null ? purchase.token.mintTxHash : null,
          mintTxHashShort : purchase.token != null && purchase.token.mintTxHash != null ? purchase.token.mintTxHash.substring(0, 6) + '...' : null,
        });

    } /* else if (this.props.id != null) {
      console.log('getting purchase with id ' + this.props.id);

      const docRef = doc(getFirestore(), "orders", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        orderId : docRef.id,
        order : result.data(),

      });
    } */
  }

  render() {



  let etherscanUrl = process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'search?f=0&q=' + this.state.mintTxHash;
    
  let mintTxHashTooltip;
  if (this.state.mintTxHash != null) {
    mintTxHashTooltip =   
      <Tooltip title={this.state.mintTxHash}>
        <Link href={etherscanUrl} target="_blank" rel="noreferrer" underline="hover">
          <Typography>{this.state.mintTxHashShort}</Typography>
        </Link>
      </Tooltip>
  }

  let metadataUrl;

  if (this.state.metadataUrl) {
    metadataUrl = 
    <Link href={this.state.metadataUrl} underline="hover" target="_blank" rel="noreferrer">
      metadata
    </Link>
  }

  let openSeaLink;
  if (this.state.tokenId && this.state.contractAddress) {
    openSeaLink = 
    <>|
    <Link href={process.env.REACT_APP_OPENSEA_URL_ROOT + this.state.contractAddress + '/' + this.state.tokenId} underline="hover" target="_blank" rel="noreferrer">
        OpenSea
    </Link></>
  }

  let refDiv;
  if (this.state.metadataUrl && openSeaLink) {
    refDiv = 
    <>|
    </>
  }

  let etherscanTokenUrl;
  if (this.state.tokenId && this.state.contractAddress) {
    etherscanTokenUrl = 
    <Link href={process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'token/' + this.state.contractAddress + '?a=' + this.state.tokenId} underline="hover"  target="_blank" rel="noreferrer">
        {this.state.tokenId}
    </Link>
  }

    return (

      <TableRow
      key={this.state.purchaseId}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      
      <TableCell component="th" scope="row">{this.state.purchaseId}</TableCell>
      <TableCell align="left">{this.state.purchaseDate}</TableCell>
      <TableCell align="left">
        <Typography>
          {this.state.orderId}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>
          {this.state.productSku}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>
          {this.state.productSerialNo}
        </Typography>
      </TableCell>
      <TableCell align="left">{this.state.tokenTs}</TableCell>
      <TableCell align="left">{etherscanTokenUrl}</TableCell>
      <TableCell align="left">
        {mintTxHashTooltip}
      </TableCell>
      <TableCell align="left">
        {metadataUrl}
        {refDiv}
        {openSeaLink}
      </TableCell>
        
    </TableRow>

    );
  }

}

export default Purchase;
