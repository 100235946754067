
import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, Alert} from '@mui/material';

import {getAuth} from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp, setDoc,} from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig, auth } from '../firebase_config.js';
import QRCode from 'react-qr-code';

initializeApp(getFirebaseConfig());


class PurchaseEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getPurchase = this.getPurchase.bind(this);
    this.savePurchase = this.savePurchase.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  getDefaultState() {
    return {
        orderId: '', 
        productSku: '', 
        productSerialNo: '', 
        mint: false
    }
  }

  async componentDidMount() {
    this.getPurchase();

  }

  componentWillUnmount() {

  }

  handleChange(event) {
    // this.setState({value: event.target.value});

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(`setting state to ${name} = ${value}`)

    this.setState({
      [name]: value
    });

  }

  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    this.savePurchase();

  }

  async getPurchase() {

    if (this.props.orderId != null) {
    
    } else if (this.props.data != null) {
      this.setState({ 
        orderId: this.props.orderId,
        purchase: this.props.data
      });
    }

  }

  async savePurchase() {
      try {
    
        var owner = getAuth().currentUser.uid;
        var oId = this.state.orderId;
        var productSku = this.state.productSku;
        var productSerialNo = this.state.productSerialNo;
        var mint = this.state.mint;
        
        console.log(`about to save a purchase with ${owner}, ${oId}, ${productSku}, ${mint}`);
  
        const orderDocRef = doc(getFirestore(), "orders", oId);
        const docSnap = await getDoc(orderDocRef);
        if (!docSnap.exists()) {
            console.log("No existing order found with id " + oId);
            this.errorState(`No existing order found with id ${oId}`);

            return;
        } 

        let found = false;
        function forEachFunction(value, index, array) {
          //console.log(`comparing ${productSku} with ${value.productSku}`)

          if (!found && productSku == value.productSku) {
            //console.log('found a match: ' + JSON.stringify(value));
            found = true;
          }
        }
        docSnap.data().lineItems.forEach(forEachFunction);

        //check that the retrieved order includes the supplied product sku
        if (!found) {
          console.log(`Provided order (${oId}) doesnt include a product with the given SKU (${productSku})`);
          this.errorState(`Provided order (${oId}) doesnt include a product with the given SKU (${productSku})`);
          return;
        }
    
        const data = {
          owner: owner,
          orderId: oId,
          productSku: productSku,
          productSerialNo: productSerialNo,
          token: {
            mint: mint,
          },
          timestamp: serverTimestamp()
        }
        var docRef = await addDoc(collection(getFirestore(), `orders/${oId}/purchases`), data);
    
        console.log(`purchase saved: ${docRef.id}`);
        this.messageState(`Purchase ${docRef.id} saved`)

        this.setState(this.getDefaultState());
      }
      catch (error) {
        console.error('Error saving new purchase', error);
        this.errorState(`Error saving new purchase: ${error}`)
      }
    }


  render() {

    let message;
    if (this.state.message) {
      message = 
      <Alert severity={this.state.severity}>{this.state.message}</Alert>
    }
    
    let qrCode;
    if (this.props.orderId != null) {

      const qrCodeTitle = 'Register Order ' + this.state.orderId;
      const qrCodeValue = `${window.location.protocol}//${window.location.host}/orders/${this.state.orderId}/register`;
      console.log('qr code value: ' + qrCodeValue);

      qrCode =  <>
        <Typography variant="h3">
          Register Later
        </Typography> 
        <Typography>
          Or register your purchase later, by scanning this code:
        </Typography>      

        <div style={{ background: 'white', padding: '16px' }}>
            <QRCode title={qrCodeTitle} value={qrCodeValue} />
        </div>
        <br/><br/><br/>
        </>
    }

    return (
      <div className="PurchaseEdit" onSubmit={this.handleSubmit}>

        <Typography variant="h2">Register your Purchase</Typography>

        {message}

        <form id="purchase-form">

          <br/>
          <TextField
            id="order-id"
            label="Order ID"
            name="orderId"
            value={this.state.orderId}
            onChange={this.handleChange}
            autoComplete="true" required
          />
          <TextField
            id="product-id"
            label="Product SKU"
            name="productSku"
            value={this.state.productSku}
            onChange={this.handleChange}
            autoComplete="true" required
          />
          <br/>
          <TextField
            id="product-serial-no"
            label="Product Serial Number"
            name="productSerialNo"
            value={this.state.productSerialNo}
            onChange={this.handleChange}
            autoComplete="true"
          />
          <br/>
          <br/>
          <FormControlLabel control={<Checkbox name="mint" value={this.state.mint} onChange={this.handleChange}/>} label="Create a Digital Collectible" />
          
          <br/>
          <br/>
          
          <Button id="purchase-submit" type="submit" variant="contained">Register Purchase</Button>

        </form>

        <br/><br/>

        {qrCode}

      </div>
    );
    
  }


  errorState(message) {
    this.setState(
      {
        message: message, 
        severity: 'error'
      }
    );
  }

  messageState(message) {
    this.setState(
      {
        message: message, 
        severity: 'success'
      }
    );
  }

}

export default PurchaseEdit;
