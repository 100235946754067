import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, Link} from '@mui/material';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";
import { getFirebaseConfig, auth, isUserSignedIn } from '../firebase_config.js';
import ProductEdit from './ProductEdit.js';
import {Navigate } from "react-router-dom";

class ProductLineEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getProductLine = this.getProductLine.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
      
  }

  getDefaultState() {
    return {
      name: '', 
      description: '', 
      symbol: '', 
      createContract: false, 
      productLine : null
    }
  }

  async componentDidMount() {
    this.getProductLine();

  }

  componentWillUnmount() {

  }

  handleChange(event) {
    //this.setState({value: event.target.value});

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(`setting state to ${name} = ${value}`)

    this.setState({
      [name]: value
    });

  }

  async handleSubmit(event) {
    //console.log('Register form submitted: ' + this.state.email);
    event.preventDefault();

    this.saveProductLine();

  }

  async getProductLine() {
    
    if (this.props.id != null) {
      console.log('getting product line with id ' + this.props.id);

      const docRef = doc(getFirestore(), "productLines", this.props.id);
      const result = await getDoc(docRef);

      this.setState({ 
        productLine : result.data()
      });
    } else if (this.props.data != null) {
      this.setState({ 
        productLine : this.props.data
      });
    }
  }

  async saveProductLine() {

    if (!auth.currentUser)
      return;

    var owner = auth.currentUser.uid;
    var plName = this.state.name;
    var plDesc = this.state.description;
    var plSymbol = this.state.symbol;
    var plContract = this.state.createContract;

    console.log(`about to save a product line with ${owner}, ${plName}, ${plSymbol}, ${plDesc}`);

    const prodLineData = {
      owner: owner,
      productLineName: plName,
      productLineDescription: plDesc,
      productLineSymbol: plSymbol,
      contract: {
        create: plContract,
        address: null,
        baseTokenUrl: null,
      },
      timestamp: serverTimestamp()
    };

    const pLineColRef = collection(getFirestore(), 'productLines');

    const docRef = await addDoc(pLineColRef, prodLineData);

    console.log(`product line saved: ${docRef.id}`);

    this.setState(this.getDefaultState());

  }

  render() {

    if (!isUserSignedIn()) {
      console.log("profile.render, user not signed in, redirecting to login");
      return <Navigate to="/login" replace={true} />
    }

    return (
      <>

        <Typography variant="h4">Create Product Line</Typography>
        <br/><br/>

        <form id="product-line-form" onSubmit={this.handleSubmit}>

          <TextField
            id="product-line-name"
            label="Name"
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
            autoComplete="true" required
          />
          
          <br/><br/>
          <TextField
            id="product-line-description"
            label="Description"
            name="description"
            value={this.state.description}
            onChange={this.handleChange}
            autoComplete="true" required
          />
          
          <br/><br/>
          <TextField
            id="product-line-symbol"
            label="Symbol"
            name="symbol"
            value={this.state.symbol}
            onChange={this.handleChange}
            autoComplete="true" required
          />

          <br/><br/>

          <FormControlLabel control={<Checkbox name="createContract" value={this.state.createContract} onChange={this.handleChange} checked/>} label="Create Contract Immediately" />
          <br/>
          * Check that you have sufficient ether in your <Link href='/profile'>default</Link> wallet before deploying a contract otherwise the contract deployment will fail and you may lose some ether. 
          <br/><br/>
          <Button id="product-line-submit" type="submit" variant="contained">Save Product Line</Button>

        </form>

        <ProductEdit />

      </>
    );
  }


}

export default ProductLineEdit;
