import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography, Link} from '@mui/material';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";

import {getAuth} from 'firebase/auth';
import { getFirebaseConfig, auth } from '../firebase_config.js';

import {getNfts, getBalanceInEth} from '../fe-eth-lib.js'
import Token from './Token.js';

class WalletDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getWallet = this.getWallet.bind(this);

  }

  getDefaultState() {
    return {nfts: [], balance: '', address: ''}
  }

  async componentDidMount() {
    this.getWallet();
  }

  componentWillUnmount() {

  }

  async getWallet() {

    if (this.props.id != null) {
      
      const address = this.props.id;

      const nfts = await getNfts(address);
      const bal = await getBalanceInEth(address);
  
      //console.log('bal: ' + bal);
  
      this.setState({
        address: address,
        balance: bal,
        nfts: nfts
      });
    } 

  }

  render() {

    
    let listItems
    
    if (this.state.nfts.ownedNfts) {
      listItems = this.state.nfts.ownedNfts.map((nftDoc, index) =>
        <Token key={index} data={nftDoc}/>
      );
    }

    return (
      <div className="WalletDetails">

        <Typography variant="h2">Wallet Details</Typography>
        <br/><br/>
        Wallet Address: {this.state.address} (View on <Link href={process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'address/'+this.state.address} target="_blank" rel="noreferrer">etherscan</Link>)
        <br/><br/>
        Balance: {this.state.balance} eth (Convert eth to fiat <Link href='https://mconvert.net/' target="_blank" rel="noreferrer">here</Link>)
        <br/><br/>       

        <Typography variant="h3">Tokens</Typography>

        {listItems}

      </div>
    );
  }

}

export default WalletDetails;
