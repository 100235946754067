
import React from 'react';
import { Typography, TableRow, TableCell, Tooltip, Link as MLink} from '@mui/material';

import {getTransfers, getContractMetadata} from '../fe-eth-lib.js'

import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';
import { Link } from 'react-router-dom';
initializeApp(getFirebaseConfig());

const ethers = require('ethers');

class Token extends React.Component {

  constructor(props) {
    super(props);
    this.state = {token: null};
  }

  async componentDidMount() {

    const token = this.props.data;

    if (!token)
      return;

    this.tokenToState(token);

  }

  async tokenToState(token) {

    const contract = token.contract;
    const metadata = token.rawMetadata;
    const tokenUri = token.tokenUri;

    this.setState({
      contractAddress: contract?.address,
      tokenId: token?.tokenId,
      error: token?.metadataError,
      title: token?.title,
      description: token?.description,
      timestamp: token?.timeLastUpdated,
      image: metadata?.image,
      externalUrl: metadata?.external_url,
      attributes: metadata?.attributes,
      tokenUri: tokenUri?.raw
    });

    if (!token.metadataError) {
      this.contractToState(contract?.address);

      this.transfersToState(contract?.address, token?.tokenId);
    }

  }

  async contractToState(address) {
    const response = await getContractMetadata(address);

    if (!response) {
      console.log('no contract metadata found');
      return;
    }

    this.setState({
      contractName: response.contractMetadata?.name,
      contractSymbol: response.contractMetadata?.symbol,
      contractTotalSupply: response.contractMetadata?.totalSupply
    });

  }

  async transfersToState(contractAddress, tokenId) {
    const response = await getTransfers(contractAddress, tokenId);

    if (!response) {
      console.log('no transaction history retrieved');
      return;
    }
    //console.log('transfers response: '  + JSON.stringify(response));

    let transfer;
    for (let i = 0; i < response.transfers.length; i++) {
      let transferTokenId = ethers.BigNumber.from( response.transfers[i].tokenId );

      //console.log(`comparing transfer tokenId ${transferTokenId} to curr tokenId ${tokenId}`)

      if (ethers.BigNumber.from(tokenId).eq(transferTokenId)) {
        transfer = response.transfers[i];
        //console.log('matching transfer found: ' + JSON.stringify(transfer));
        break;
      }
    }

    if (!transfer) {
      console.log('no transfer found for tokenId ' + tokenId);
      return;
    }

    this.setState({
      transferHash: transfer.hash,
      transferFrom: transfer.from,
      transferTo: transfer.to,
      transferBlockNum: transfer.blockNum,
      transferTimestamp: transfer.metadata?.blockTimestamp
    });

  }

  componentWillUnmount() {
  }

  render() {

    let metadata;
    let errorMessage;
    let transferHistory;
    let contractMetadata;
    if (this.state.error) {
      errorMessage = <>
        Error: {this.state.error}
        <br/>
      </>
      return;
    } else {
      metadata = <>
        <Typography variant='h4'>Token Summary</Typography><br/>
        Title: {this.state.title}
        <br/>
        Description: {this.state.description}
        <br/>
        External URL: <MLink href={this.state.externalUrl} target='_blank'>{this.state.externalUrl}</MLink>
        <br/>
        Image: <img src={this.state.image} width='150' height='150' alt='token image'/>
        <br/>
        Token URL: <MLink href={this.state.tokenUri} target='_blank'>{this.state.tokenUri}</MLink>
        <br/>
      </>



      if (this.state.transferHash) {

        let hashLink = process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'tx/' + this.state.transferHash;

        let fromLink = process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'address/' + this.state.transferFrom;
        let toLink = process.env.REACT_APP_ETHERSCAN_URL_ROOT + 'address/' + this.state.transferTo;

        transferHistory = <>
          <br/>
          <Typography variant='h4'>Transfer history</Typography><br/>
          From: <MLink href={fromLink} target='_blank'>{this.state.transferFrom}</MLink><br/>
          To: <MLink href={toLink} target='_blank'>{this.state.transferTo}</MLink><br/>
          Block number: {this.state.transferBlockNum}<br/>
          Hash number: <MLink href={hashLink} target='_blank'>{this.state.transferHash}</MLink> <br/>
          Timestamp: {this.state.transferTimestamp}<br/>

        </>
      }

      if (this.state.contractName) {
        contractMetadata = 
        <>
          (Name: {this.state.contractName}, Symbol: {this.state.contractSymbol}, Total Supply: {this.state.contractTotalSupply})
        </>
      }
    }

    return (
      <>
        Contract Address: 
        <Link to={`/productline?contractAddress=${this.state.contractAddress}`}>
          {this.state.contractAddress} 
        </Link>
        {contractMetadata}
        <br/>
        Token ID: {this.state.tokenId}
        <br/>
        Timestamp: {this.state.timestamp}
        <br/>
        {errorMessage}
        {metadata}
        {transferHistory}
        <hr/>
      </>

    );
  }

}

export default Token;
