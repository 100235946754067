import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AboutStart(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>Getting Started</Typography>

            <Typography lineHeight={2}>
                Once you've created an <Link underline="hover" to='/about/register'>account</Link>, there are a few different paths you can take depending on what you want to achieve. <br/>
                This page will help guide you depending on what you want to achieve. 
            </Typography>

            <Typography variant='h6' marginTop={4} marginBottom={1}>
                Do you have products that you want create as digital collectibles?
            </Typography>
            <Typography lineHeight={2} marginY={2}>
                Learn how to setup and configure products so they can be converted to <Link underline="hover" to='/about/collectibles'>digital collectibles</Link> when purchased by your customers. <br/>
                <Link underline="hover" to='/about/products'>Learn more</Link>
            </Typography>
            
            <Typography variant='h6' marginTop={4} marginBottom={1}>
                Have you purchased a product that you want to convert to a digital collectible?
            </Typography>
            <Typography lineHeight={2}>
                Learn how to find and claim your order, then convert that to a <Link underline="hover" to='/about/collectibles'>digital collectible</Link> that you can view in your online <Link to='/about/profile'>wallet</Link>. <br/>
                <Link underline="hover" to='/about/purchases'>Learn more</Link>
            </Typography>
            
            <Typography variant='h6' marginTop={4} marginBottom={1}>
                Do you want to find out more about your profile and online wallets?
            </Typography>
            <Typography lineHeight={2}>
                Learn about creating and managing online wallets within tokemax, as well as accessing <Link underline="hover" to='/about/collectibles'>digital collectible</Link> that are in your wallet. <br/>
                <Link underline="hover" to='/about/profile'>Learn more</Link>
            </Typography>
        </>
    )
}  

export default AboutStart;
