import { Typography, Link as mLink } from "@mui/material";
import { Link } from "react-router-dom";

function AboutUs(props) {
  
    return (
        <>
            <Typography variant='h2' marginTop={4} marginBottom={4}>About Us</Typography>

            <Typography marginTop={2} marginBottom={2} lineHeight={3}>  
                tokemax was started in 2021 in Australia. <br/>

                Our mission was simple - fuse real world consumer activities with the online world, 
                enabling businesses to engage with their customers in new and exciting ways. <br/>

                tokemax uses blockchain technology to help businesses create unique loyalty experiences as well as a variety of other services. <br/>

                Easily integrate your existing inventory and ordering process to create a unique and secure purchase history for your customers. <br/>

                Customer purchases can be stored as NFTs on the blockchain - information that can then be used in a growing number of community platforms and services. <br/>

                If you would like to learn more, please visit our <Link to='/about/contact'>getting started</Link> page.<br/>
                
                We would love to hear from you, you can reach us via our <Link to='/about/contact'>contact</Link> page.<br/>

            </Typography>

            <Typography variant='h4' marginTop={4} marginBottom={4} id='whats-in-a-name'>Whats in a name?</Typography>
            <Typography marginTop={2} marginBottom={2} lineHeight={2}>  
                tokemax (pronounced toh-ke-max) is inspired from the <Link to='https://en.wikipedia.org/wiki/Tokamak_Fusion_Test_Reactor'>tokamak</Link> fusion reactor. <br/>

                the inspiration for the name came from the mission to fuse the real world with online blockchains. <br/>
                
                The max part at the end is just saying we want to do maximum fusion.  <br/>
            </Typography>
        </>
    )
}  

export default AboutUs;
