import React from 'react';

import {TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Tooltip, Link} from '@mui/material';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp,} from "firebase/firestore";

import { auth } from '../firebase_config.js';

class OrderLineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getData = this.getData.bind(this);

  }

  getDefaultState() {
    return {}
  }

  async componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {

  }

  async getData() {

    // console.log('using index for key: ' + this.props.key);

    if (this.props.data != null) {
      this.setState({
        lineItem: this.props.data,
        // index: this.props.key
      });
    } 

  }

  render() {

    if (!this.state.lineItem)
      return;

    return (
      <>

        <TableRow key={this.state.lineItem.productSku}>
          <TableCell align="left">
            <Tooltip title="Register purchase">
              <Link href={`/orders/${this.props.orderId}/register?sku=${this.state.lineItem.productSku}`} underline="hover">
                {this.state.lineItem.productSku} 
              </Link>
            </Tooltip>
          </TableCell>
          <TableCell align="right">{this.state.lineItem.productAmount}</TableCell>
          <TableCell align="right">{this.state.lineItem.quantity}</TableCell>
        </TableRow>

      </>
    );
  }

}

export default OrderLineItem;
