import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import { getFirebaseConfig, auth, isUserSignedIn, setUserSignedIn } from '../firebase_config.js';
import { BottomNavigation, CssBaseline, Link } from '@mui/material';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(null);
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      displayName: currentUser != null? currentUser.displayName : null,
      email: currentUser != null ? currentUser.email : null,
      photoURL: currentUser != null ? currentUser.photoURL : null,
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {

  }
  
  render() { 
    return (
    
      <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="lg">
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', } }}>
            <Button
              key="aboutContact"
              sx={{ my: 1, color: '#4568dc', display: 'block' }}
              href="/about/contact">
              Contact
            </Button>
            <Button
              key="aboutUs"
              sx={{ my: 1, color: '#4568dc', display: 'block' }}
              href="/about/us">
              About Us
            </Button>

          </Box>

          <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
              tokemax
            {' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
          

        </Container>
      </Box>

    )
  }
  

  render_appbar() {

    return (
       
      <AppBar position="relative" bottom="0" style={{ background: '#F5F5F5' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters="true">

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', } }}>
              <Button
                key="aboutContact"
                sx={{ my: 2, color: '#4568dc', display: 'block' }}
                href="/about/contact">
                Contact
              </Button>
              <Button
                key="aboutUs"
                sx={{ my: 2, color: '#4568dc', display: 'block' }}
                href="/about/us">
                About Us
              </Button>
            </Box>
          

          </Toolbar>      
        </Container>
      </AppBar>
    );
  }

}

export default Footer;
