import { createContext, useContext, useEffect, useState } from "react";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged} from "firebase/auth";
import {auth} from "../firebase_config.js"

const userAuthContext = createContext();

export function UserAuthContextProvider({children}) {
    
    const [user, setUser] = useState(""); 

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            //console.log('UserAuthContext.onAuthStateChanged, currentUser: ' + currentUser);
            setUser(currentUser);
            //console.log('UserAuthContext.onAuthStateChanged, state.user: ' + user);
        });
        return () => {
            unsubscribe();
        }

        // const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            // setIsSignedIn(!!user);
        // });
        // return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    

        }, []);
    
    return (
    <userAuthContext.Provider value={{user, signup, login}}>
        {children}
    </userAuthContext.Provider>
    )
}

export function useUserAuth(){
    return useContext(userAuthContext);
}