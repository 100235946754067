import React from 'react';
import { Typography,  Avatar, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableHead, Tooltip} from '@mui/material';

import Purchase from './Purchase';
import PurchaseEdit from './PurchaseEdit';

import {getAuth} from 'firebase/auth';

import { getFirestore, doc, getDoc, collection, addDoc, serverTimestamp, query, where, getDocs, collectionGroup,} from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';

const firebaseApp = initializeApp(getFirebaseConfig());
const auth = getAuth(firebaseApp);

class Purchases extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(null);

    this.getPurchases = this.getPurchases.bind(this);
      
    this.createAuthHook();
  }

  createAuthHook() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {

        this.setState(this.getDefaultState(user));

        this.getPurchases();

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      purchases: []
    }
  }

  async componentDidMount() {
    this.getPurchases();
  }

  async getPurchases() {

    //console.log('getting purchases, isSignedIn: ' + this.state.isSignedIn);
    if (!this.isUserSignedIn()) {
      return;
    }

    const owner = auth.currentUser.uid;
    console.log('getting purchases for owner ' + owner);

    const q = query(collectionGroup(getFirestore(), "purchases"), where("owner", "==", owner)); 

    const querySnapshot = await getDocs(q);
    let plData = querySnapshot.docs.map(doc => doc);


    /*plData.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });*/

    this.setState({ 
      purchases: plData 
    });
  }

  render() {
    const listItems = this.state.purchases.map((plDoc) =>
      <Purchase key={plDoc.id} data={plDoc}/>
    );

    let plEdit;
    //if (this.state.productLineEditDisplay) {
    if (true) {
      plEdit = <PurchaseEdit/>
    }

    let dName;
    if (this.state.currentUser != null)
      dName = this.state.currentUser.displayName + ', '


    let table;
    if (this.state.currentUser != null)
      table = 
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Purchases" >
        <TableHead >
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Purchase date</TableCell>
            <TableCell align="left">Order ID</TableCell>
            <TableCell align="left">Product SKU</TableCell>
            <TableCell align="left">Product Serial Number</TableCell>
            <TableCell align="left">Token date</TableCell>
            <TableCell align="left">Token ID</TableCell>
            <TableCell align="left">Token Hash</TableCell>
            <TableCell align="left">References</TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
              {listItems}
            </TableBody>
          </Table>
        </TableContainer>



    return (
      
      <div className="Purchases">
      <br/>
          
          {plEdit}

          <p>
            {dName} Your number of purchases is {this.state.purchases.length}
          </p>

          {table}
          
          <br/>
      </div>
    );
  }


  isUserSignedIn() {
    var result = !!auth.currentUser;
    //console.log('isUserSignedIn: ' + result);
    return result;
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(`in didUpdate, checking state counts ${prevState.purchases.length} against current ${this.state.purchases.length}`);
    //if (prevState.purchases.length !== this.state.purchases.length) {
      //this.getPurchases();
    //}
  }

  static getDerivedStateFromProps(props, state) {
    //console.log(`in getDerivedStateFromProps, checking props ${JSON.stringify(props)} and state ${JSON.stringify(state)}`);
    //this.getPurchases();
    //return {purchases: []};
    return null;
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    //console.log(`in getSnapshotBeforeUpdate, checking props ${JSON.stringify(prevProps)} and state ${JSON.stringify(prevState)}`);
    return null;
  }


}

export default Purchases;
