import React from 'react';

import { getFirestore, doc, getDoc } from "firebase/firestore";
// import { initializeApp } from 'firebase/app';
import { auth, isUserSignedIn } from '../firebase_config.js';
// import { getFirebaseConfig } from '../firebase_config.js';
import { Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Tooltip, Link, IconButton, Collapse, Box, Paper} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OrderLineItem from './OrderLineItem.js';

// initializeApp(getFirebaseConfig());

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {orderId: null, order : null};

    this.getOrder = this.getOrder.bind(this);
      
  }

  async componentDidMount() {
    this.getOrder();

  }

  componentWillUnmount() {

  }

  async getOrder() {
    
    //console.log('loading product line data into state from ' + this.props.data.data());

    if (this.props.data != null) {
      // last line of this state is setting the order line items box to be collapsed initially
      this.setState({
        orderId : this.props.data.id,
        order : this.props.data.data(),
        [this.props.data.id]: false,
        open: false
      });
    } else if (this.props.id != null) {
      console.log('getting order with id ' + this.props.id);

      const docRef = doc(getFirestore(), "orders", this.props.id);
      const result = await getDoc(docRef);

      // last line of this state is setting the order line items box to be collapsed initially
      this.setState({ 
        orderId : docRef.id,
        order : result.data(),
        [docRef.id]: false,
        open: false
      });
    } 
  }

  /**
   * Used to expand / collapse line item rows
   */
  handleClick(key) {

    let value = this.state.open

    if (value == null)
      value = false;
    else
      value = !value;

    //console.log(`in handle click, key: ${key}, value: ${value}`);

    this.setState(
      {
        [key]: value,
        open: value
      }
    );
  }

  render() {

    let order;
    if (this.state.order !=null) {

      let open = this.state.open;

      let iconButton;
      if (this.state.order.lineItems) {
        iconButton = 
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => this.handleClick(this.state.orderId)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      }

      order = 
      <>
      <TableRow
        key={this.state.orderId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        
        <TableCell>
          {iconButton}
        </TableCell>
        <TableCell component="th" scope="row">{this.state.orderId}</TableCell>
        <TableCell align="left">{this.state.order.orderId}</TableCell>
        <TableCell align="left">
          <Typography>
            {this.state.order.orderDate}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>
            {this.state.order.orderAmount}
          </Typography>
        </TableCell>
        <TableCell align="left">{this.state.order.sellingMerchant}</TableCell>
          
      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {this.renderLineItems(this.state.order.lineItems)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>

    } else {
      //console.log(JSON.stringify(this.state));
    }

    //

    return (
      <>
        {order}
      </>
    );
  }

  //      {index} Product SKU: {lineItem.productSku}


  renderLineItems(lineItems) {
    if (!lineItems)
      return '';
      
    const listItems = lineItems.map((lineItem, index, array) =>
        <OrderLineItem key={lineItem.productSku} orderId={this.state.orderId} data={lineItem}/>
    );

    return (
      <>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="OrderLineItems" >
            <TableHead >
              <TableRow>
                <TableCell align="left">Product SKU</TableCell>
                <TableCell align="right">Product Amount</TableCell>
                <TableCell align="right">Product Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listItems}
            </TableBody>
          </Table>
        </TableContainer>

      </>
    );
    
  }

}

export default Order;