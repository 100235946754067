import * as React from 'react';
import ReactDOM from 'react-dom';
import { Typography,  Avatar, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableHead, Tooltip, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// import {getAuth} from 'firebase/auth';

import {  getFirestore, collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
// import { initializeApp } from 'firebase/app';
//const firebaseApp = initializeApp(getFirebaseConfig());
//const auth = getAuth(firebaseApp);
import { getFirebaseConfig, auth, isUserSignedIn } from '../firebase_config.js';

import {Link, Navigate } from "react-router-dom";
import WalletEdit from './WalletEdit.js';
import {getNfts, getBalanceInEth} from '../fe-eth-lib.js'


class Profile extends React.Component {
    
  constructor(props) {
      super(props);
      this.state = this.getDefaultState(null);

      this.getProfile = this.getProfile.bind(this);
      this.createAuthHook = this.createAuthHook.bind(this);

      this.createAuthHook();

    }

    createAuthHook() {
      auth.onAuthStateChanged(async (user) => {
        if (user) {

           console.log('onAuthStateChanged for user ' + user.uid);

          this.setState(this.getDefaultState(user));
  
          this.getProfile();
  
        } else {
          // User is signed out
          this.setState(this.getDefaultState(null));
        }
      })
    }
  
    getDefaultState(currentUser) {
      return {
        isSignedIn: currentUser == null? false : true,
        currentUser: currentUser,
        displayName: currentUser != null? currentUser.displayName : null,
        email: currentUser != null ? currentUser.email : null,
        photoURL: currentUser != null ? currentUser.photoURL : null,
        profile: null,
        wallets: [],
      }
    }
  
    async componentDidMount() {
      this.getProfile();
  
    }
    
    componentWillUnmount() {
  
    }

    async getProfile() {

      if (!isUserSignedIn()) {
        return;
      }

      if (!auth.currentUser)
        return;

      const owner = auth.currentUser.uid;
  
      //console.log('getting profile for owner ' + owner);

      const q = query(collection(getFirestore(), "userProfile"), where("owner", "==", owner));
  
      const querySnapshot = await getDocs(q);
      let plData = querySnapshot.docs.map(doc => doc);

      const walletColRef = collection(getFirestore(), `userProfile/${owner}/wallets`);
      const walletQuerySnapshot = await getDocs(walletColRef);
      
      let walletData = walletQuerySnapshot.docs.map(doc => doc);

      //console.log('got wallets for owner: ' + walletData.length);
  
      const s = { 
        profile: plData,
        wallets: walletData
      }
  
      this.setState(s);
    }
  
    
    render() {

      if (!isUserSignedIn()) {
        console.log("user not signed in, redirecting to login");
        return <Navigate to="/login" replace={true} />
      }

      let walletTable;

      if (this.state.wallets != null) {
        walletTable = 
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Wallets" >
            <TableHead >
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Label</TableCell>
                <TableCell align="left">Purpose</TableCell>
                <TableCell align="left">Public Key</TableCell>
                <TableCell align="left">Private Key</TableCell>
                <TableCell align="left">Mnemonic</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.wallets.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{row.id}</TableCell>
                  <TableCell>{row.data().label}</TableCell>
                  <TableCell>{row.data().purpose}</TableCell>
                  <TableCell align="left">
                    <Tooltip title={row.data().publicKey}>
                    <Typography>
                      {row.data().publicKey.substring(0, 6)}...
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title={row.data().privateKey}>
                      <Typography>
                        {row.data().privateKey.substring(0, 6)}...
                        </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title={row.data().mnemonic ? row.data().mnemonic : ''}>
                      <Typography>
                        {row.data().mnemonic && row.data().mnemonic.substring(0, 6)}...
                        </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Link to={'/profile/wallet/'+row.data().address} underline="hover">
                      {row.data().address}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{row.data().timestamp.toDate().toDateString()}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={'Delete wallet ' + row.data().label}>
                      <IconButton aria-label="delete" onClick={() => {
                          deleteDoc(doc(getFirestore(), `userProfile/${auth.currentUser.uid}/wallets`, row.id));
                          
                          alert('deleted ' + row.id);
                          //alert('delete ' + row.id);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

       return (
           <>   
           
            <Typography variant='h2'>Your Profile</Typography>

            <Typography>Display name: {this.state.displayName}</Typography>

            <Typography>Email: {this.state.email}</Typography>

            <Typography variant='h4'>Wallets</Typography>

            <br/><br/>

            {walletTable}

            <br/><br/>

            <WalletEdit/>


           </> 
        );
    }
    
    // Returns true if a user is signed-in.
    // isUserSignedIn() {
    //   var result = !!getAuth().currentUser;
    //   //console.log('isUserSignedIn: ' + result);
    //   return result;
    // }
  }
  
  export default Profile;
  