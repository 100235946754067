import React from 'react';

import {TextField, FormControlLabel, Checkbox, Button, Typography} from '@mui/material';


import { getFirebaseConfig, auth, isUserSignedIn } from '../firebase_config.js';


import {getContractMetadata, getNftsForContract, getOwnersForContract} from '../fe-eth-lib.js'
import {Link, Navigate } from "react-router-dom";
import Token from './Token.js';

class Contract extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();

    this.getContract = this.getContract.bind(this);

    this.createAuthHook = this.createAuthHook.bind(this);

    this.createAuthHook();

  }

  createAuthHook() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {

         //console.log('onAuthStateChanged for user ' + user.uid);

        this.setState(this.getDefaultState(user));

        //this.getContract();

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      displayName: currentUser != null? currentUser.displayName : null,
      email: currentUser != null ? currentUser.email : null,
      photoURL: currentUser != null ? currentUser.photoURL : null,
      contract: null,
      nfts: [],
      owners: [],
    }
  }

  async componentDidMount() {
    this.getContract();
  }

  componentWillUnmount() {

  }

  async getContract() {

    if (this.props.id != null) {
    
      const address = this.props.id;

      try {
        const contract = await getContractMetadata(address);
    
        console.log('contract: ' + JSON.stringify(contract));
    
        this.setState({
          address: address,
          contract: contract
        });
      } catch (error) {

        this.setState({
          address: address
        });

        console.log(error);
      }

      this.getNfts(address);
      this.getOwners(address);

    } 
  }

  async getNfts(address) {
    const nfts = await getNftsForContract(address);

    console.log('retrieved nfts: ' + JSON.stringify(nfts));

    this.setState({
      nfts: nfts.nfts
    });
  }

  async getOwners(address) {

    const owners = await getOwnersForContract(address);

    console.log('retrieved owners: ' + JSON.stringify(owners));

    this.setState({
      owners: owners.owners
    });
  }

  render() {
    if (!isUserSignedIn()) {
      //console.log("profile.render, user not signed in, redirecting to login");
      return <Navigate to="/login" replace={true} />
    }

    const nfts = this.state.nfts.map((nft, index) =>
    <>
      <Token key={index+100000} data={nft}/><br/>
    </>
      
    );


    const owners = this.state.owners.map((owner, index) =>
      <><Link key={index} to={'/profile/wallet/'+owner}>{owner}</Link><br/></>
    );

    return (
      <div className="Contract">

        <Typography variant="h2">Product Line Contract</Typography>
        <br/><br/>
        Contract Address: {this.state.address}<br/>
        Contract Name: {this.state.contract?.name}<br/>
        Contract Symbol: {this.state.contract?.symbol}<br/>
        Contract Total Supply: {this.state.contract?.totalSupply}<br/>


        <Typography variant="h4">Product Line NFTs</Typography>
        <br/><br/>
        {nfts}

        <Typography variant="h4">Product Line NFT Owners</Typography>
        <br/><br/>
        {owners}
      </div>
    );
  }

}

export default Contract;
