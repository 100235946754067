
import React from 'react';

import ProductView from './ProductView';
import ProductEdit from './ProductEdit';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirebaseConfig } from '../firebase_config.js';
import { Typography, TableRow, TableCell, Tooltip, Link, TableContainer, Paper, Table, TableHead, TableBody} from '@mui/material';

initializeApp(getFirebaseConfig());

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {products: []};

    this.getProducts = this.getProducts.bind(this);

      
  }

  async componentDidMount() {
    this.getProducts();

  }

  componentWillUnmount() {

  }


  async getProducts() {
    
    console.log('getting products for line ' + this.props.productLineId);

     const prodColRef = collection(getFirestore(), `productLines/${this.props.productLineId}/products`);
     const querySnapshot = await getDocs(prodColRef);
    
    let prodData = querySnapshot.docs.map(doc => doc);

    /*plData.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });*/


    this.setState({ 
      products: prodData ,
    });
  }


  render() {

    //console.log('products length: ' + this.state.products.length);

    // this.state.products.forEach(
    //   pDoc => console.log('products entries: ' + pDoc.id + ', ' + JSON.stringify(pDoc.data()))
    // );

    const listItems = this.state.products.map((pDoc) =>
      <ProductView key={pDoc.id} data={pDoc.data()}/>   
    );

    //console.log('product view tags: ' + listItems);
    

    let table;
    if (this.state.currentUser != null) {
      table = 

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Products" >
          <TableHead >
            <TableRow>
              <TableCell align="left">Product SKU</TableCell>
              <TableCell align="left">Product name</TableCell>
              <TableCell align="left">Product image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    }

    return (
     
      <>
        <p>
          The number of products is: {this.state.products.length}
        </p>


        {listItems}
        
        <br/>
        
        <ProductEdit productLineId={this.props.productLineId} />
      </>
    );
  }


}

export default Products;
