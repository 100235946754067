import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import CollectablePng from "../assets/collection.png";
import LoyaltyPng from "../assets/customer-loyalty.png";
import AuthenticityPng from "../assets/guarantee.png";


export default function AboutOverview(props) {

    let loyalty = AboutOverviewLoyaltyPrograms_v2();
    let col = collectibles_v2();
    let auth = authenticity_v2();

    return (

        <>

            <Grid container alignItems={"center"} justify="space-around" style={{ border: "solid 0px" }}>
                <Grid item xs={12} sm={6} md={3}>
                {loyalty}
                </Grid>
                <Grid item xs={12} sm={6} md={3} marginX={{ xs: 0, md: 6 }} marginY={{ xs: 4, md: 0 }}>
                {col}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                {auth}
                </Grid>
            </Grid>

        </>
    )
} 

/**
 * @deprecated
 * @returns 
 */
function AboutOverviewLoyaltyPrograms() {
    
    return (
        <>
            <Typography variant='h4'>Loyalty Programs</Typography>
            <Typography lineHeight={2} marginTop={2} marginBottom={4}>
                You can claim your verified purchases of products and experiences and store them as a digital collectible. <br/>
                You can show your loyalty to a particular store or even to a particular brand regardless of which retail outlet you purchased items of that brand from. <br/>
                Obtaining many collectibles for a given store or brand can open up opportunities for discounts, unique experiences and schwag in line with the offerings from that store / brand.<br/>
            </Typography>
            <Link to='/about/loyalty'>Learn More</Link>
        </>
    )
}

function AboutOverviewLoyaltyPrograms_v2() {
    
    let head = 'Loyalty Programs';
    let body = <>
        Create loyalty programs leveraging digital collectibles that unlock new possibilities for you and your customers. <br/>
        Work with like minded brands to create combined loyalty programs for customers you share. <br/>
        Engage directly with your consumers regardless of how they access your products and services. <br/>
    </>;
    let url = '/about/loyalty';
    
    let cardRes = card(head, body, url, LoyaltyPng, head);

    return (
        <>{cardRes}</>
    )
/*

        You can claim your verified purchases of products and experiences and store them as a digital collectible. <br/>
        You can show your loyalty to a particular store or even to a particular brand regardless of which retail outlet you purchased items of that brand from. <br/>
        Obtaining many collectibles for a given store or brand can open up opportunities for discounts, unique experiences and schwag in line with the offerings from that store / brand.<br/>
        */
}

function collectibles_v2() {
    
    let head, body, url, cardRes;

    head = 'Digital Collectibles';
    body = <>
        Create digital collectibles from verified purchases. <br/>
        Combining unique details about the seller and the buyer, the digital collectible will capture the relationship between the two.<br/>
        The collectible is stored as an NFT on the blockchain which can be traded online. <br/><br/><br/>
    </>
    url = '/about/collectibles';

    cardRes = card(head, body, url, CollectablePng, head);

    return (<>{cardRes}</>)

}

function authenticity_v2() {
    
    
    let head, body, url, cardRes;

    head = 'Purchase Authenticity';
    body = <>
        Creating a digital collectible for a purchase allows your customer to prove that they made the purchase.<br/>
        Proof of purchase can be critical to many scenarios, especially in the luxury goods and second hand market. <br/>

        Show proof of purchase by displaying a digital collectible in your online wallet for a verified purchase. <br/>
    </>
    url = '/about/authenticity';

    cardRes = card(head, body, url, AuthenticityPng, head);
    
    return (<>{cardRes}</>)
/*
    Show proof of purchase by displaying a digital collectible in your online wallet for a verified purchase. <br/>
    This can be especially valuable when selling unique items on the second hand market. Being able to demonstrate guaranteed proof of purchase provides confidence in your second hand target market. <br/>
    Even if you dont want to resell your purchase, having a safe and secure record of your purchase can help with everyday activities such as getting support for a product, requesting a warranty claim or even confirming a purchase prior to leaving a review.<br/>

*/
}

function card(head, body, url, image, imageTitle) {

    return (
        <>
            <Box sx={{ minWidth: 300, maxWidth: 300 }}>
                <Card variant="outlined" sx={{ height: 550 }}>
                    <CardMedia
                        sx={{ height: 140 }}
                        image={image}
                        title={imageTitle}/>
                    <CardContent>
                        <Typography variant='h5' color='#4568dc'>{head}</Typography>
                        <Typography lineHeight={2} marginTop={2} marginBottom={4}>
                            {body}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href={url}>Learn More</Button>
                    </CardActions>
                </Card>
            </Box>
        </>
    )
}

function collectibles() {
    
    return (
        <>
            <Typography variant='h4' id='digital-collectibles'>Digital Collectibles</Typography>
            <Typography lineHeight={2} marginTop={2} marginBottom={4}>
                Create digital collectibles from verified purchases. <br/>
                Combining unique details about vendor and the purchasor, the collectible will capture the relationship between the two parties.<br/>
                The collectible is stored as an NFT on the blockchain which can be traded online.  <br/>
                <Link to='/about/collectibles'>Learn More</Link>
            </Typography>
        </>
        )
}



function authenticity() {
    
    return (
        <>
        </>
        )
}
