//import logo from './logo.svg';
import { Route, BrowserRouter as Router, Routes, Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import ProductLines from './components/ProductLines';
import Welcome from './components/Welcome';
import Navigation from './components/Navigation';
// import ProductEdit from './components/ProductEdit';
import ProductLineEdit from './components/ProductLineEdit';
import Orders from './components/Orders';
import Purchases from './components/Purchases';
// import OrderEdit from './components/OrderEdit';
import PurchaseEdit from './components/PurchaseEdit';
import Profile from './components/Profile';
import WalletDetails from './components/WalletDetails';


import { getFirebaseConfig, auth, isUserSignedIn } from './firebase_config.js';
import 'firebase/compat/auth';

//import {useUserAuth} from "./context/UserAuthContext";

import { UserAuthContextProvider } from "./context/UserAuthContext";

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ProductLine from './components/ProductLine';
import Contract from './components/Contract';
import Footer from './components/Footer';

import AboutDigCol from './about/collectibles';
import AboutLoyalty from './about/loyalty';
import AboutAuthenticity from './about/authenticity';
import AboutStart from './about/start';
import AboutProducts from './about/products';
import AboutProfile from './about/profile';
import AboutPurchases from './about/purchases';
import AboutRoadmap from './about/roadmap';
import AboutContact from './about/contact';
import AboutBlockchain from './about/blockchain';
import AboutRegister from './about/register';
import AboutUs from './about/us';
import { Container } from '@mui/material';

function App(props) {

  return (
    
      <Router  >

        <UserAuthContextProvider>
        
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}>

              <Navigation/>

              <Container maxWidth="lg" disableGutters="true" sx={{ border: 0, paddingLeft: 2, paddingRight: 2 }} >
              
                <Routes>

                  <Route exact path='/' element={<Welcome />}></Route>
                  <Route exact path='/register' element={<Register/>}></Route>  
                  <Route exact path='/login' element={<Login/>}></Route>
                            
                  <Route exact path='/productline' element={
                      <RequireAuth>
                        <ProductLineParam/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/productline/contract/:id' element={
                      <RequireAuth>
                        <ContractParam/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/products' element={
                      <RequireAuth>
                        <ProductLines/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/product/edit' element={
                      <RequireAuth>
                        <ProductLineEdit/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/orders' element={
                      <RequireAuth>
                        <Orders/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/orders/:id/register' element={
                      <RequireAuth>
                        <PurchaseEditParam/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/purchases' element={
                      <RequireAuth>
                        <Purchases/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/profile' element={
                      <RequireAuth>
                        <ProfileParam/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/profile/wallet/:id' element={
                      <RequireAuth>
                        <WalletDetailsParam/>
                      </RequireAuth>
                    }>
                  </Route>

                  <Route exact path='/about/collectibles' element={<AboutDigCol/>}></Route>  
                  <Route exact path='/about/loyalty' element={<AboutLoyalty/>}></Route>  
                  <Route exact path='/about/authenticity' element={<AboutAuthenticity/>}></Route>  
                  <Route exact path='/about/start' element={<AboutStart/>}></Route>  
                  <Route exact path='/about/products' element={<AboutProducts/>}></Route>  
                  <Route exact path='/about/profile' element={<AboutProfile/>}></Route>  
                  <Route exact path='/about/purchases' element={<AboutPurchases/>}></Route>  
                  <Route exact path='/about/roadmap' element={<AboutRoadmap/>}></Route>  
                  <Route exact path='/about/contact' element={<AboutContact/>}></Route>  
                  <Route exact path='/about/blockchain' element={<AboutBlockchain/>}></Route>  
                  <Route exact path='/about/register' element={<AboutRegister/>}></Route>  
                  <Route exact path='/about/us' element={<AboutUs/>}></Route>  

                </Routes>      
              
              </Container>

              <Footer/>

          </Box>

        </UserAuthContextProvider>

      </Router>
    
  );
}

function RequireAuth({ children }) {
  
//  const authed = auth.currentUser !== null;
  const location = useLocation();

  //const {user} = useUserAuth() ;

  const isAuthenticated = isUserSignedIn();
 
  //console.log('in reqAuth, is authed: ' + authed + ", curUsr: " + auth.currentUser + ", userContext.user: " + user + ", isAuthenticated: " + isAuthenticated);

  if (isAuthenticated) {
    //console.log('isAUthenticated is '+isAuthenticated+', returning children ' + children);
    return children;
  } else {
    //console.log('isAUthenticated is false, returning login redirect' );
    return <Navigate to="/login" replace state={{ path: location.pathname }}/>;
  }

}

function PurchaseEditParam() {

  const { id } = useParams();

  return (
      <>
          <PurchaseEdit orderId={id} />
      </>
  );
}

function WalletDetailsParam() {

  const { id } = useParams();

  return (
      <>
          <WalletDetails id={id} />
      </>
  );
}

function ProductLineParam() {

  const [searchParams, setSearchParams] = useSearchParams();
  const contractAddress = searchParams.get("contractAddress")

  //console.log('ProductLineParam contractAddress: ' + contractAddress);

  return (
      <>
          <ProductLine contractAddress={contractAddress} />
      </>
  );
}

function ContractParam() {

  const { id } = useParams();

  return (
      <>
          <Contract id={id} />
      </>
  );
}

function ProfileParam() {

  const [searchParams, setSearchParams] = useSearchParams();
  const walletAddress = searchParams.get("wallet")

  //console.log('ProductLineParam walletAddress: ' + walletAddress);

  return (
      <>
          <Profile walletAddress={walletAddress} />
      </>
  );
}

export default App;
