import React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from "../assets/logo.png";


import { getFirebaseConfig, auth, isUserSignedIn, setUserSignedIn } from '../firebase_config.js';
import { Divider } from '@mui/material';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(null);
    this.createAuthHook = this.createAuthHook.bind(this);

    this.createAuthHook();

  }

  createAuthHook() {

    auth.onAuthStateChanged(async (user) => {
      //console.log('Navigation.authStateChange, user: ' + user);

      if (user) {
        this.setState(this.getDefaultState(user));

      } else {
        // User is signed out
        this.setState(this.getDefaultState(null));
        setUserSignedIn(false)
        
      }
    })
  }

  getDefaultState(currentUser) {
    return {
      isSignedIn: currentUser == null? false : true,
      currentUser: currentUser,
      displayName: currentUser != null? currentUser.displayName : null,
      email: currentUser != null ? currentUser.email : null,
      photoURL: currentUser != null ? currentUser.photoURL : null,
      anchorElUser: null,
      anchorElNav: null,
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {

  }

  setAnchorElUser(target) {
    this.setState({anchorElUser: target});
  }

  setAnchorElNav(target) {
    this.setState({anchorElNav: target});
  }
  
  render() {

    let handleOpenUserMenu = (event) => {
      this.setAnchorElUser(event.currentTarget);
    };
    let handleCloseUserMenu = () => {
      this.setAnchorElUser(null);
    };
  
    let handleOpenNavMenu = (event) => {
      this.setAnchorElNav(event.currentTarget);
    };
    let handleCloseNavMenu = () => {
      this.setAnchorElNav(null);
    };

    let regButton, loginButton;
    if (!this.state.isSignedIn) {
      regButton =
      <Button
        key="Register"
        sx={{ my: 2, color: '#4568dc', display: 'block' }}
        href="/register">
        Register
      </Button>

      loginButton =
        <Button
          key="login"
          sx={{ my: 2, color: '#4568dc', display: 'block' }}
          href="/login">
          Login
        </Button>
    }

    let productListButton, productEditButton, ordersButton, purchasesButton, profileMenu, contactButton;

    let aboutButton, aboutMenu;

    if (this.state.isSignedIn) {
      productListButton =
        <Button
          key="products"
          sx={{ my: 2, color: '#4568dc', display: 'block' }}
          href="/products">
          List My Products
        </Button>

      productEditButton = 
        <Button
          key="productsEdit"
          sx={{ my: 2, color: '#4568dc', display: 'block' }}
          href="/product/edit">
          Add Products
        </Button>

       ordersButton = 
       <Button
        key="orders"
        sx={{ my: 2, color: '#4568dc', display: 'block' }}
        href="/orders">
        List My Orders
      </Button> 

      purchasesButton = 
        <Button
          key="purchases"
          sx={{ my: 2, color: '#4568dc', display: 'block' }}
          href="/purchases">
          List My Purchases
        </Button>

      profileMenu = 
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={this.state.anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(this.state.anchorElUser)}
          onClose={handleCloseUserMenu}
          disableScrollLock={true}
        >
          <MenuItem key="profile" onClick={handleCloseUserMenu}>
            <Button
              key="profile"
              href="/profile">
              Profile
            </Button>
          </MenuItem>
          <MenuItem key="logOut" onClick={handleCloseUserMenu}>
            <Button
              key="logOut"
              onClick={() => auth.signOut()}>
              Log out
            </Button>
          </MenuItem>		
        </Menu>
    }

    contactButton = 
    <Button
      key="aboutContact"
      sx={{ my: 2, color: '#4568dc', display: 'block' }}
      href="/about/contact">
      Contact
    </Button>
    
    aboutButton = 
    <Button
        key="about"
        sx={{ my: 2, color: '#4568dc', display: 'block' }}
        onClick={handleOpenNavMenu}>
        About
    </Button>

    aboutMenu = 
    <Menu
      sx={{ mt: '45px', color: '#4568dc' }}
      id="menu-about"
      anchorEl={this.state.anchorElNav}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(this.state.anchorElNav)}
      onClose={handleCloseNavMenu}
      disableScrollLock={true}

    >
      <Divider>Services</Divider>
      <MenuItem key="loyalty" onClick={handleCloseNavMenu}>
        <Button
          key="loyalty"
          href="/about/loyalty">
          Loyalty Programs
        </Button>
      </MenuItem>
      <MenuItem key="collectibles" onClick={handleCloseNavMenu}>
        <Button
          key="collectibles"
          href="/about/collectibles">
          Digital Collectibles
        </Button>
      </MenuItem>
      <MenuItem key="authenticity" onClick={handleCloseNavMenu}>
        <Button
          key="authenticity"
          href="/about/authenticity">
          Purchase Authenticity
        </Button>
      </MenuItem>
      <Divider>Accounts</Divider>
      <MenuItem key="register" onClick={handleCloseNavMenu}>
        <Button
          key="register"
          href="/about/register">
          Registering with us
        </Button>
      </MenuItem>
      <MenuItem key="profile" onClick={handleCloseNavMenu}>
        <Button
          key="profile"
          href="/about/profile">
          Your Profile
        </Button>
      </MenuItem>
      <Divider/>
      <MenuItem key="products" onClick={handleCloseNavMenu}>
        <Button
          key="products"
          href="/about/products">
          Managing Products
        </Button>
      </MenuItem>
      <MenuItem key="purchases" onClick={handleCloseNavMenu}>
        <Button
          key="purchases"
          href="/about/purchases">
          Registering Purchases
        </Button>
      </MenuItem>
      <Divider>Company</Divider>
      <MenuItem key="us" onClick={handleCloseNavMenu}>
        <Button
          key="us"
          href="/about/us">
          About Us
        </Button>
      </MenuItem>
      <MenuItem key="contact" onClick={handleCloseNavMenu}>
        <Button
          key="contact"
          href="/about/contact">
          Contact Us
        </Button>
      </MenuItem>
      <MenuItem key="roadmap" onClick={handleCloseNavMenu}>
        <Button
          key="roadmap"
          href="/about/roadmap">
          Product Roadmap
        </Button>
      </MenuItem>
    </Menu>

    return (
       
      <AppBar position="static" style={{ background: '#F5F5F5', marginBottom: '12px' }} elevation={3} >
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Box
              component="img"
              sx={{height: 56, py: 2}}
              alt="tokemax"
              src={Logo} href="/"/>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '#4568dc',
                textDecoration: 'none',
                paddingLeft: 1
              }}
            >
              tokemax
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

              {regButton}
              
              {loginButton}

              <Divider orientation="vertical" variant="middle" flexItem />

              {productEditButton}
              
              {productListButton}

              {ordersButton}
                
              {purchasesButton}

              {aboutButton}
              {aboutMenu}
              
              {contactButton}

            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" onClick={handleOpenNavMenu}>
                <MenuIcon />
              </IconButton>
                
              {regButton}

            </Box>
          
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile Menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={this.state.displayName} src={this.state.photoURL}/>
                </IconButton>
              </Tooltip>

              {profileMenu}
              
            </Box>

          </Toolbar>      
        </Container>
      </AppBar>
    );
  }

}

export default Navigation;
